import React from "react";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";

const sectionBackgroundStyle = {
  textAlign: "center",
  padding: 10,
  color: "#959595",
  marginBottom: "20px",
};

const Footer = () => {
  return (
    <Box style={sectionBackgroundStyle}>
      <Typography
        component="p"
        variant="p"
        sx={{
          fontSize: 14,
          fontFamily: "Open Sans, Medium",
        }}
      >
        Copyright © 2022 All Rights Reserved
      </Typography>
    </Box>
  );
};

export default Footer;
