import { css } from "@emotion/css";
import axios from "axios";
import { toast } from "react-toastify";

let storageValue = null;
const storageUserKey = "h7mbzDH0p6NZ";

export const setUserKey = (value) => {
    storageValue = value;
    sessionStorage.setItem(storageUserKey, value ? JSON.stringify(value) : {});
};

export const setToken = (token) => {
    const userKey = getUserKey();
    const sessionValue = userKey ? { ...userKey, token } : { token };
    setUserKey(sessionValue);
};

export const getUserKey = () => {
    if (storageValue) {
        return storageValue;
    } else {
        const userKey = sessionStorage.getItem(storageUserKey);
        return userKey ? JSON.parse(userKey) : null;
    }
};

export const deleteUserKey = () => {
    storageValue = null;
    sessionStorage.setItem(storageUserKey, null);
};

export function convertDataURIToBinary(dataURI) {
    const BASE64_MARKER = ";base64,";
    const base64Index = dataURI.indexOf(BASE64_MARKER) + BASE64_MARKER.length;
    const base64 = dataURI.substring(base64Index);
    const raw = window.atob(base64);
    const rawLength = raw.length;
    const array = new Uint8Array(new ArrayBuffer(rawLength));

    for (let i = 0; i < rawLength; i++) {
        array[i] = raw.charCodeAt(i);
    }
    return array;
}

export function readFileData(fileInput) {
    return new Promise((resolve, reject) => {
        const f = fileInput[0];

        if (f) {
            if (/(jpe?g|png|gif)$/i.test(f.type)) {
                const r = new FileReader();
                r.onload = function (e) {
                    const base64Img = e.target.result;
                    const binaryImg = convertDataURIToBinary(base64Img);
                    const blob = new Blob([binaryImg], { type: f.type });
                    const blobURL = window.URL.createObjectURL(blob);
                    const result = {
                        name: f.name,
                        type: f.type,
                        size: f.size,
                        blobUrl: blobURL,
                        base64: base64Img,
                        blob: blobURL,
                        binary: JSON.stringify(binaryImg, null, 2),
                    };
                    resolve(result);
                };
                r.readAsDataURL(f);
            } else {
                reject("Failed file type");
            }
        } else {
            reject("Failed to load file");
        }
    });
}

export const fetchData = async (url, payload) => {
    let params = "";
    try {
        if (payload) {
            params = new url.URLSearchParams(payload);
        }

        let res = await axios.get(`${url}?${params}`);
        return res.data.response;
    } catch (error) {
        console.error(error);
    }
};

export const postData = async ({
    url,
    payload,
    getFullResponse,
    highlightError,
    navigate,
}) => {
    const userKey = getUserKey() || {};

    if (userKey.token) {
        payload.token = userKey.token;
    }

    if (userKey.id) {
        payload.user_id = userKey.id;
    }

    try {
        let res = await axios.post(url, payload);

        if (
            res.data.status !== "success" &&
            (res.data.statusCode === "403" || res.data.statusCode === "401") &&
            navigate
        ) {
            navigate("/login");
            if (highlightError) {
                toast.error(res.data.msg);
            }
            return;
        } else if (res.data.status !== "success" && highlightError) {
            toast.error(res.data.msg);
        }

        return getFullResponse ? res.data : res.data.response;
    } catch (error) {
        console.log("Something went wrong");
    }
};

export const commonCarouselNavStyle = css({
    backgroundColor: "#49494949 !important",
});
