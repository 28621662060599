import { css, keyframes } from "@emotion/css";
import { Box, Typography } from "@mui/material";

export const SectionHeading = ({
    sx = {},
    children,
    color,
    textAlign,
    ...restProps
}) => (
    <Typography
        sx={{
            color: color || "#DE6B07",
            fontSize: { xs: "21px", md: "30px" },
            fontFamily: "cinzel, serif",
            textAlign: textAlign || "center",
            marginBottom: "20px",
            ...sx,
        }}
        {...restProps}
    >
        {children}
    </Typography>
);

export const SecondaryHeading = ({
    sx = {},
    children,
    color,
    textAlign,
    ...restProps
}) => (
    <Typography
        variant="body2"
        sx={{
            color: color || "#A620B3",
            fontSize: { xs: "18px", md: "24px" },
            fontFamily: "Josefin Slab",
            textAlign: textAlign || "center",
            fontWeight: "bold",
            marginBottom: "20px",
            ...sx,
        }}
        {...restProps}
    >
        {children}
    </Typography>
);

export const ContentText = ({
    sx = {},
    children,
    bold,
    textAlign,
    ...restProps
}) => (
    <Typography
        variant="body2"
        sx={{
            fontSize: { xs: "16px", md: "18px" },
            fontFamily: "futura-lt, sans-serif",
            fontWeight: bold ? "bold" : "normal",
            marginBottom: "16px",
            ...sx,
        }}
        {...restProps}
    >
        {children}
    </Typography>
);

export const MediaImage = (props) => <Box component="img" {...props} />;

export const MediaBackgroundImage = ({
    sx = {},
    backdropColor,
    opacity,
    ...restProps
}) => (
    <>
        <Box
            sx={{
                position: "absolute",
                width: "100%",
                height: "100%",
                top: 0,
                left: 0,
                zIndex: -2,
                background: backdropColor || "#f8e3fb",
            }}
        ></Box>
        <Box
            component="img"
            tabIndex="-1"
            sx={{
                position: "absolute",
                zIndex: -1,
                width: "100%",
                height: "100%",
                opacity: opacity || 0.3,
                top: 0,
                left: 0,
                ...sx,
            }}
            {...restProps}
        />
    </>
);
export const MediaBackgroundVideo = ({
    sx = {},
    backdropColor,
    backgroundGradient,
    opacity,
    ...restProps
}) => (
    <>
        <Box
            sx={{
                position: "absolute",
                top: 0,
                left: 0,
                width: "100%",
                height: "100%",
                zIndex: -1,
                background: backdropColor || "#2b074d",
            }}
        ></Box>
        <Box
            sx={{
                position: "absolute",
                height: "100%",
                width: "100%",
                top: 0,
                left: 0,
                zIndex: -1,
                opacity: opacity || 0.3,
                backgroundImage: backgroundGradient || "none",
            }}
        >
            <video
                style={{ objectFit: "cover" }}
                role="presentation"
                crossOrigin="anonymous"
                playsInline
                preload="auto"
                height="100%"
                width="100%"
                muted
                loop
                tabIndex="-1"
                autoPlay
                {...restProps}
            />
        </Box>
    </>
);

export const ContentList = ({ textItems, color, bold, ...restProps }) => (
    <ul style={{ listStyleType: "none" }}>
        {textItems.map((item, index) => (
            <li
                style={{ display: "flex", alignItems: "baseline" }}
                key={`item-${index}`}
            >
                <Box
                    sx={{
                        width: "7px",
                        borderRadius: 10,
                        background: color || "#fff",
                        height: "7px",
                        marginRight: "15px",
                    }}
                ></Box>
                <ContentText bold={bold} color={color || "#FFF"}>
                    {item}
                </ContentText>
            </li>
        ))}
    </ul>
);

const slideIn = keyframes({
    "0%": {
        transform: "translateX(-5px)",
    },
    "100%": {
        transform: "translateX(0px)",
    },
});

const strokeAnimation = keyframes({
    "100%": { strokeDashoffset: "-35%" },
});

const polygonAnimation = keyframes({
    "0%,100%": {
        clipPath: `polygon(
        0% 45%,
        16% 44%,
        33% 50%,
        54% 60%,
        70% 61%,
        84% 59%,
        100% 52%,
        100% 100%,
        0% 100%
      )`,
    },
    "50%": {
        clipPath: `polygon(
        0% 60%,
        15% 65%,
        34% 66%,
        51% 62%,
        67% 50%,
        84% 45%,
        100% 46%,
        100% 100%,
        0% 100%
      )`,
    },
});

const lightsAnimation = keyframes({
    "0%": {
        color: "#b8bee0",
        textShadow:
            "0 0 1em #ff00aa, 0 0 0.125em #ff33bb, -1em -0.125em 0.5em #ffbb33, 1em 0.125em 0.5em #33bbff",
    },
    "30%": {
        color: "#d1d8fa",
        textShadow:
            "0 0 1em #ff00aa, 0 0 0.125em #ff33bb, -0.5em -0.125em 0.25em #ffbb33, 0.5em 0.125em 0.25em #33bbff",
    },
    "40%": {
        color: "#e5eaff",
        textShadow:
            "0 0 1em #ff00aa, 0 0 0.125em #ffccee, -0.25em -0.125em 0.125em #ffbb33, 0.25em 0.125em 0.125em #33bbff",
    },
    "70%": {
        color: "#d1d8fa",
        textShadow:
            "0 0 1em #ff00aa, 0 0 0.125em #ff33bb, 0.5em -0.125em 0.25em #ffbb33, -0.5em 0.125em 0.25em #33bbff",
    },
    "100%": {
        color: "#b8bee0",
        textShadow:
            "0 0 1em #ff00aa, 0 0 0.125em #ff33bb, 1em -0.125em 0.5em #ffbb33, -1em 0.125em 0.5em #33bbff",
    },
});

const nameAnimationStyle = css({
    animationName: slideIn,
    animationDuration: "5s",
    animationTimingFunction: "linear",
    animationIterationCount: "infinite",
    animationDirection: "alternate",
    animationFillMode: "forwards",
    animationDelay: -1,
});
