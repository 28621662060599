import React from "react";
import Box from "@mui/material/Box";

import pg_background from "../../assets/site/landing/pg-background.webp";

export const BackgroundImg = () => (
    <Box
        sx={{
            position: "absolute",
            height: "100%",
            width: "100%",
            top: 0,
            zIndex: -10,
        }}
    >
        <Box
            sx={{
                backgroundImage: `url(${pg_background})`,
                height: "100%",
                width: "100%",
                backgroundSize: "100% 100%",
                opacity: "0.7",
            }}
        ></Box>
    </Box>
);
