import React from "react";
import { Box, Button, Container, Grid, Typography } from "@mui/material";
import PowerSettingsNewRoundedIcon from "@mui/icons-material/PowerSettingsNewRounded";

import image_instagram from "../assets/site/image_instagram.png";
import image_facebook from "../assets/site/facebook.png";
import image_youtube from "../assets/site/youtube.png";
import image_linkedin from "../assets/site/linkedin.png";
import { css } from "@emotion/css";
import { UserPaymentHistory } from "./user-payment-history/UserPaymentHistory";
import { deleteUserKey } from "../common/utils";
import { useNavigate } from "react-router";
import { config } from "../env/config";

const supportTextStyle = css({
    color: "#48255D",
    fontStyle: "Open Sans, Medium",
    fontSize: "16px",
});

const More = () => {
    const navigate = useNavigate();

    const handleLogout = () => {
        deleteUserKey();
        navigate("/login");
    };

    return (
        <Container maxWidth="lg" sx={{ mt: 3, mb: 4 }}>
            <Grid container spacing={2}>
                <Grid
                    display={{ xs: "none", md: "flex" }}
                    item
                    md={12}
                    textAlign="right"
                >
                    <Button
                        variant="contained"
                        sx={{
                            background: "#FAFAFA",
                            color: "#000",
                        }}
                        onClick={handleLogout}
                    >
                        <PowerSettingsNewRoundedIcon />
                        Logout
                    </Button>
                </Grid>
            </Grid>

            <Grid container spacing={2} mt={2}>
                <Grid item md={12}>
                    <Typography
                        component="p"
                        variant="p"
                        sx={{ fontSize: 18, fontFamily: "Open Sans, Semibold" }}
                    >
                        Connect with Shreekalp
                    </Typography>
                </Grid>
                <Grid item md={6} width="100%">
                    <SocialBox
                        logo={image_instagram}
                        title="Instagram"
                        subTitle="divine_harmony_with_shreekalp"
                        url="https://www.instagram.com/divine_harmony_with_shreekalp/"
                    />
                </Grid>
                <Grid item md={6} width="100%">
                    <SocialBox
                        logo={image_youtube}
                        title="YouTube"
                        subTitle="Strolls with shreekalp"
                        url="https://www.youtube.com/channel/UCKWqc8NGiI9e8dabrcvqWuQ"
                    />
                </Grid>
                <Grid item md={6} width="100%">
                    <SocialBox
                        logo={image_facebook}
                        title="Facebook"
                        subTitle="Club mango friends"
                        url="https://www.facebook.com/ClubMangoFriends"
                    />
                </Grid>
                <Grid item md={6} width="100%">
                    <SocialBox
                        logo={image_linkedin}
                        title="Linkedin"
                        subTitle="Spiritual meeting with Shreekalp"
                        url="https://www.linkedin.com/company/spiritual-meeting/"
                    />
                </Grid>
            </Grid>

            <UserPaymentHistory />

            <Grid container spacing={2} mt={5}>
                <Grid item xs={12}>
                    <Typography
                        component="p"
                        variant="p"
                        sx={{ fontSize: 18, fontFamily: "Open Sans, Semibold" }}
                    >
                        Support
                    </Typography>
                </Grid>
                <Grid item md={12}>
                    <Button
                        href={
                            config.env === "global"
                                ? "tel:+19173997899"
                                : "tel:+919503692077"
                        }
                        style={{
                            textTransform: "none",
                        }}
                    >
                        <Typography
                            component="p"
                            variant="p"
                            className={supportTextStyle}
                        >
                            Operational Address: 804, H4 Valley Shilp, Sector No
                            36, Kharghar, Navi Mumbai 410210
                        </Typography>
                    </Button>
                </Grid>
                <Grid
                    item
                    md={6}
                    sx={{
                        borderRight: { xs: "none", md: "1px solid #C8C8C8" },
                    }}
                >
                    <Button
                        href={
                            config.env === "global"
                                ? "tel:+19173997899"
                                : "tel:+919503692077"
                        }
                        style={{
                            textTransform: "none",
                        }}
                    >
                        <Typography
                            component="p"
                            variant="p"
                            className={supportTextStyle}
                        >
                            {`Call Helpline : ${
                                config.env === "global"
                                    ? "+1 (917) 399-7899"
                                    : "+91 9604489537"
                            }`}
                        </Typography>
                    </Button>
                </Grid>
                <Grid item md={6}>
                    <Button
                        style={{
                            textTransform: "none",
                        }}
                        color="secondary"
                        href="mailto:contactus@divineharmony.co.in?subject=info"
                    >
                        <Typography
                            component="p"
                            variant="p"
                            className={supportTextStyle}
                        >
                            Email Support : contactus@divineharmony.co.in
                        </Typography>
                    </Button>
                </Grid>
                <Grid
                    item
                    md={6}
                    sx={{
                        borderRight: { xs: "none", md: "1px solid #C8C8C8" },
                    }}
                >
                    <Button
                        target="_blank"
                        sx={{
                            textTransform: "none",
                        }}
                        href="https://global.eonlineworkshop.com/g_termsnconditions.html"
                    >
                        <Typography
                            component="p"
                            variant="p"
                            className={supportTextStyle}
                        >
                            Terms and Conditions
                        </Typography>
                    </Button>
                </Grid>
                <Grid item md={6}>
                    <Button
                        target="_blank"
                        sx={{
                            textTransform: "none",
                        }}
                        href="https://global.eonlineworkshop.com/g_privacypolicy_refund.html"
                    >
                        <Typography
                            component="p"
                            variant="p"
                            className={supportTextStyle}
                        >
                            Privacy Policy
                        </Typography>
                    </Button>
                </Grid>
            </Grid>
        </Container>
    );
};

const SocialBox = ({ logo, title, subTitle, url }) => {
    return (
        <Box
            sx={{
                display: "flex",
                columnGap: "18px",
                alignItems: "center",
                border: "1px solid #E6E6E6",
                borderRadius: "6px",
                padding: { xs: "15px", md: "15px 60px" },
                width: "100%",
                maxWidth: "100%",
            }}
        >
            <Box
                component="img"
                alt={title}
                width="32px"
                height="32px"
                src={logo}
                mr={1}
            />
            <Box>
                <Typography
                    fontSize="18px"
                    fontFamily="Open Sans, Semibold"
                    color="#000000"
                    paddingLeft="5px"
                >
                    {title}
                </Typography>
                <Button
                    variant="text"
                    size="small"
                    target={`${url ? "_blank" : "_self"}`}
                    href={`${url ? url : "#"}`}
                    sx={{
                        height: "40px",
                    }}
                >
                    {subTitle}
                </Button>
            </Box>
        </Box>
    );
};

export default More;
