import * as React from "react";
import Box from "@mui/material/Box";

import Login from "../../login/Login";
import Header from "../header/Header";
import Footer from "../footer/Footer";
import { getUserKey } from "../utils";

const pageStyle = {
  display: "flex",
  flexDirection: "column",
  height: "100%",
};
const contentStyle = {
  display: "flex",
  flexGrow: "1",
  flexDirection: "column",
};
const unauthorisedPageStyle = {
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  flexGrow: 1,
};

const UnauthorisedPage = () => (
  <Box sx={pageStyle}>
    <Header
      unauth
      title="Divine Harmony"
      drawerOpen={false}
      toggleDrawer={() => {}}
    />
    <Box sx={unauthorisedPageStyle}>
      <p>You are not authorised to view this page</p>
    </Box>
    <Footer />
  </Box>
);

const Page = ({ unauth, children, title = "Divine Harmony" }) => {
  const [drawerOpen, setDrawerOpen] = React.useState(true);
  const userData = getUserKey();

  const toggleDrawer = () => {
    setDrawerOpen(!drawerOpen);
  };

  if (!unauth && !userData) {
    return <Login />;
  }

  return (
    <Box sx={pageStyle}>
      <Header
        unauth={unauth}
        title="Event Booking"
        drawerOpen={drawerOpen}
        toggleDrawer={toggleDrawer}
      />
      <Box sx={contentStyle}>{children}</Box>
      <Footer />
    </Box>
  );
};

export default Page;
