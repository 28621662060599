import { Button } from "@mui/material";
import SimCardDownloadOutlinedIcon from "@mui/icons-material/SimCardDownloadOutlined";

import { config } from "../env/config";
import axios from "axios";
// import downloadjs from "downloadjs";
// import html2canvas from "html2canvas";

export const DownloadReceipt = ({ url }) => {
    const handleCaptureClick = async (htmlEl) => {
        // var template = document.createElement("template");
        // template.innerHTML = htmlEl.trim();
        // document.body.append(template.content.firstChild)
        // // Change this to div.childNodes to support multiple top-level nodes.
        // // return div.firstChild;
        // const canvas = await html2canvas(template.content.firstChild);
        // const dataURL = canvas.toDataURL("image/png");
        // downloadjs(dataURL, "download.png", "image/png");
        // var iframe = document.createElement("iframe");
        // document.body.appendChild(iframe);
        // setTimeout(function () {
        //   const download = async () => {
        //     var iframedoc = iframe.contentDocument || iframe.contentWindow.document;
        //     iframedoc.body.innerHTML = htmlEl;
        //     const canvas = await html2canvas(iframedoc.body, {
        //       onrendered: function (canvas) {
        //         document.body.appendChild(canvas);
        //         document.body.removeChild(iframe);
        //       },
        //     });
        //     const dataURL = canvas.toDataURL("image/png");
        //     downloadjs(dataURL, "download.png", "image/png");
        //   };
        //   download();
        // }, 10);
    };

    const handleReceiptDownload = async () => {
        // fetchData(`${config.serverUrl}/${url}`);

        const response = await axios.get(`${config.serverUrl}/${url}`);
        handleCaptureClick(response.data);
        // return response.data;
        // fetch(e.target.href, {
        //   method: "GET",
        //   headers: {},
        // })
        //   .then((response) => {
        // response.data.arrayBuffer().then(function (buffer) {
        //   const url = window.URL.createObjectURL(new Blob([buffer]));
        //   const link = document.createElement("a");
        //   link.href = url;
        //   link.setAttribute("download", "image.png"); //or any other extension
        //   document.body.appendChild(link);
        //   link.click();
        // });
        //   })
        //   .catch((err) => {
        //     console.log(err);
        //   });
    };
    return (
        <Button
            variant="text"
            size="small"
            target={`${url ? "_blank" : "_self"}`}
            href={`${url ? `${config.serverUrl}/${url}` : "#"}`}
            sx={{
                height: "40px",
                fontSize: 13,
                fontFamily: "Open Sans, Regular",
                color: "#48255D",
            }}
            //   onClick={handleReceiptDownload}
        >
            <SimCardDownloadOutlinedIcon />
            View/Download Receipt
        </Button>
    );
};
