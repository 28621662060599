import React from "react";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import { Grid } from "@mui/material";

import events_heading_bg from "../../assets/site/landing/events_heading_bg.webp";
import design_heading from "../../assets/site/landing/design_heading.webp";
import glimpse_1 from "../../assets/site/landing/glimpse_1.webp";
import glimpse_2 from "../../assets/site/landing/glimpse_2.webp";
import glimpse_3 from "../../assets/site/landing/glimpse_3.webp";
import glimpse_4 from "../../assets/site/landing/glimpse_4.webp";
import glimpse_5 from "../../assets/site/landing/glimpse_5.webp";
import { ContactUs } from "../components/ContactUs";
import { Header } from "../components/Header";
import { BackgroundImg } from "../components/BackgroundImg";
import {
    ContentList,
    ContentText,
    MediaImage,
    SecondaryHeading,
    SectionHeading,
} from "../../common/typography";
import Carousel from "react-material-ui-carousel";
import { commonCarouselNavStyle } from "../../common/utils";

const glimpseList = [glimpse_2, glimpse_3, glimpse_4, glimpse_5, glimpse_1];

const AboutEvents = () => (
    <Grid item xs={12}>
        <div style={{ textAlign: "center" }}>
            <MediaImage
                src={design_heading}
                sx={{
                    width: { xs: 230, md: 338 },
                    height: { xs: 26, md: 46 },
                }}
            />
        </div>
        <Container maxWidth="md" sx={{ pb: 2, background: "#ffffff4f" }}>
            <SectionHeading>events</SectionHeading>
            <SecondaryHeading>
                Come and Experience the bliss of energy transformation with
                Shreekalp
            </SecondaryHeading>
            <ContentText bold>
                Divine Harmony Organization offers many spiritual programs under
                the guidance of our enlightened spiritual mentor Shreekalp ji.
                Spiritual does not mean religious; all faiths are welcome.
            </ContentText>
            <ContentText bold>
                Many programs have been conducted worldwide to spread Love,
                Knowledge and Happiness.
            </ContentText>
            <ContentText bold>
                All the programs have been designed by Shreekalpji based on
                current situation in the society, demand of time and various
                needs of different group of people. These programs are related
                to balancing our energies which will gives us stress release,
                peace of mind and many more benefits related to our physical,
                mental, emotional and spiritual health.
            </ContentText>
            <ContentText bold>
                For now most of the programs are conducting in online video
                meeting mode which is very effective and giving good results for
                human energy transformation. Current ongoing online events are-
            </ContentText>

            <ContentList
                color="#000"
                bold
                textItems={[
                    "Divine Harmony with Shreekalp",
                    "Spiritual Meeting with Shreekalp",
                    "Workshop for Self-Development with Shreekalp",
                    "Yuva Shibir with Shreekalp",
                    "Career Guidance Program with Shreekalp",
                ]}
            />
        </Container>
    </Grid>
);
const Glimpse = () => (
    <Grid item xs={12} mb={5}>
        <div style={{ textAlign: "center" }}>
            <MediaImage
                src={design_heading}
                sx={{
                    width: { xs: 230, md: 338 },
                    height: { xs: 26, md: 46 },
                }}
            />
        </div>
        <Container maxWidth="md">
            <SectionHeading>Glimpse of Divine Harmony Events</SectionHeading>
        </Container>
        <Carousel
            navButtonsAlwaysVisible
            navButtonsProps={{
                className: commonCarouselNavStyle,
            }}
        >
            {glimpseList.map((img) => (
                <Box style={{ position: "relative" }}>
                    <Box
                        key={img.id}
                        component="img"
                        sx={{
                            width: "100%",
                            height: {
                                xs: "280px",
                                md: "500px",
                            },
                        }}
                        alt="Landing Photo"
                        src={img}
                    />
                </Box>
            ))}
        </Carousel>
    </Grid>
);
const PublicShreekalpInfo = () => {
    return (
        <div>
            <Box>
                <Header currentPage="events" />
                <Grid container spacing={{ xs: 1, md: 3 }}>
                    <Grid item xs={12} mb={3}>
                        <MediaImage
                            src={events_heading_bg}
                            sx={{
                                width: "100%",
                                height: { xs: 200, md: 340 },
                            }}
                        />
                    </Grid>
                    <AboutEvents />
                    <Glimpse />
                    <ContactUs />
                </Grid>
            </Box>

            <BackgroundImg />
        </div>
    );
};

export default PublicShreekalpInfo;
