import * as React from "react";
import { toast } from "react-toastify";
import { Button } from "@mui/material";
import { WhatsappJoinDialog } from "./WhatsappJoinDialog";

import { config } from "../env/config";
import { getUserKey, postData } from "../common/utils";
import image_logo from "../assets/site/logo.png";
import { Loader } from "../common/loader/Loader";

export const PayNowButton = ({ event, refreshEvents }) => {
    const [showLoader, setShowLoader] = React.useState(false);
    const [paymentUpdateResponse, setPaymentUpdateResponse] =
        React.useState(null);

    const userData = getUserKey();
    let eventBookOrderDetailsLocal = {};

    const sendPaymentUpdate = async (response) => {
        const payload = {
            booked_by: userData.name,
            country_id: userData.country_id,
            email: userData.email,
            event: {
                booking_enddate: event.booking_enddate,
                booking_endtime: event.booking_endtime,
                btnStatus: event.btnStatus,
                eventCancelReason: event.eventCancelReason,
                eventStatus: event.eventStatus,
                event_amount: event.event_amount,
                event_for: event.event_for,
                event_time: event.event_time,
                event_type: event.event_type,
                eventdate: event.eventdate,
                eventname: event.eventname,
                id: event.id,
                isCancel: event.isCancel,
                participant: event.participant,
            },
            member_id: userData.member_id,
            online_payment_id: eventBookOrderDetailsLocal.id,
            order_id: response.razorpay_order_id,
            payment_status: "success",
            signature: response.razorpay_signature,
            state_id: userData.state_id,
            transaction_id: response.razorpay_payment_id,
            user_id: userData.id,
        };

        const res = await postData({
            url: `${config.serverUrl}/eventBooking.php`,
            payload,
        });
        setShowLoader(false);
        if (res) {
            toast.success("Booking Successful!");

            if (res.whatsapplink) {
                setPaymentUpdateResponse(res);
            } else {
                hideWhatsappDialog();
            }
        }
    };

    const hideWhatsappDialog = () => {
        setPaymentUpdateResponse(null);
        refreshEvents();
    };

    //Function to load razorpay script for the display of razorpay payment SDK.
    function loadRazorpayScript(src) {
        return new Promise((resolve) => {
            const script = document.createElement("script");
            script.src = src;
            script.onload = () => {
                resolve(true);
            };
            script.onerror = () => {
                resolve(false);
            };
            document.body.appendChild(script);
        });
    }

    //function will get called when clicked on the pay button.
    async function displayRazorpayPaymentSdk({
        amount,
        apiKeyId,
        currency,
        description,
        email,
        name,
        mobile,
        transactionId,
        razorpayAmount,
    }) {
        const res = await loadRazorpayScript(
            "https://checkout.razorpay.com/v1/checkout.js"
        );

        if (!res) {
            setShowLoader(false);
            alert("Razorpay SDK failed to load. please check are you online?");
            return;
        }
        setShowLoader(false);

        const options = {
            key: apiKeyId || config.razorAppKey,
            amount: razorpayAmount || amount * 100,
            currency: currency,
            name: "Divine Harmony",
            description: description,
            image: { image_logo },
            order_id: transactionId,
            // callback_url: "http://127.0.0.1:8000/razorpay_callback",
            // redirect: true,
            prefill: {
                name: name,
                email: email,
                contact: mobile,
            },
            handler: function (response) {
                if (
                    response.razorpay_payment_id &&
                    response.razorpay_order_id &&
                    response.razorpay_signature
                ) {
                    sendPaymentUpdate(response);
                } else {
                    setShowLoader(false);
                }
            },
            notes: {
                address: "",
            },
            theme: {
                color: "#61dafb",
            },
        };

        const paymentObject = new window.Razorpay(options);
        paymentObject.on("payment.failed", function (response) {
            setShowLoader(false);
        });
        paymentObject.open();
    }

    const onBookEventClickHandler = async () => {
        setShowLoader(true);
        try {
            const payload = {
                mobile: userData.mobile,
                description: "Event Booking",
                userId: userData.member_id,
                currency: userData.currency,
                email: userData.email,
                apkRefNo: `${userData.member_id}${Number(new Date())}`,
                bookInfo: `${event.eventname} ${event.eventdate}`,
                booked_by: userData.name,
                event_amount: Number(event.event_amount),
                event_for: event.event_for,
                eventname: event.eventname,
                eventid: event.id,
                participant: event.participant,
            };
            const res = await postData({
                url: `${config.serverUrl}/getEventBookOrderId.php`,
                payload,
            });
            if (res) {
                // toast.success("Receipt Downloaded!");
                eventBookOrderDetailsLocal = res;
                displayRazorpayPaymentSdk(res);
            } else {
                setShowLoader(false);
            }
        } catch (error) {
            setShowLoader(false);
            toast.error("Something went wrong!");
            console.log("Something went wrong");
        }
    };

    return (
        <>
            <Button
                variant="contained"
                size="small"
                onClick={onBookEventClickHandler}
                sx={{
                    width: { xs: "100%", md: "220px" },
                    height: "40px",
                    background: "#9045BF",
                    display: `${event.isCancel === "1" ? "none" : "flex"}`,
                }}
            >
                BOOK YOUR SEAT
                <Loader show={showLoader} />
            </Button>

            {/* Show join whatsapp button when update response contains whatsappLink */}
            {paymentUpdateResponse?.whatsapplink ? (
                <WhatsappJoinDialog
                    show={paymentUpdateResponse.whatsapplink}
                    primaryMsg={paymentUpdateResponse.first}
                    secondaryMsg={paymentUpdateResponse.second}
                    whatsappLink={paymentUpdateResponse.whatsapplink}
                    onCloseHandler={hideWhatsappDialog}
                />
            ) : null}
        </>
    );
};
