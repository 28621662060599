import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import MenuItem from "@mui/material/MenuItem";
import Menu from "@mui/material/Menu";
import MenuIcon from "@mui/icons-material/Menu";
import Stack from "@mui/material/Stack";

import { deleteUserKey } from "../utils";
import image_logo from "../../assets/site/logo.png";
import { useLocation, useNavigate } from "react-router";
import { Button } from "@mui/material";
import { Container } from "@mui/system";
import { css } from "@emotion/css";
import { NavLink } from "react-router-dom";

const menuItemStyle = {
  fontSize: 14,
  fontFamily: "Open Sans, Regular",
  fontWeight: 400,
};

export default function Header({ unauth, title, drawerOpen, toggleDrawer }) {
  const navigate = useNavigate();
  const location = useLocation();
  const currentPath = location.pathname;
  const [anchorEl, setAnchorEl] = React.useState(null);
  const isMenuOpen = Boolean(anchorEl);
  const menuId = "primary-search-account-menu";

  const handleProfileMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = () => {
    deleteUserKey();
    handleMenuClose();
    navigate("/login");
  };

  const renderMenu = (
    <Menu
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "left",
      }}
      keepMounted
      transformOrigin={{
        vertical: "top",
        horizontal: "left",
      }}
      id={menuId}
      open={isMenuOpen}
      onClose={handleMenuClose}
    >
      <MenuItem
        sx={{
          color: currentPath === "/dashboard" ? "#FF827C" : "#000",
        }}
        style={menuItemStyle}
        onClick={() => navigate("/dashboard")}
      >
        Home
      </MenuItem>
      <MenuItem
        sx={{
          color: currentPath === "/courses" ? "#FF827C" : "#000",
        }}
        style={menuItemStyle}
        onClick={() => navigate("/courses")}
      >
        Courses
      </MenuItem>
      <MenuItem
        sx={{
          color: currentPath === "/shreekalp" ? "#FF827C" : "#000",
        }}
        style={menuItemStyle}
        onClick={() => navigate("/shreekalp")}
      >
        Shreekalp
      </MenuItem>
      <MenuItem
        sx={{
          color: currentPath === "/more" ? "#FF827C" : "#000",
        }}
        style={menuItemStyle}
        onClick={() => navigate("/more")}
      >
        More
      </MenuItem>
      <MenuItem
        sx={{
          color: currentPath === "/more" ? "#FF827C" : "#000",
        }}
        style={menuItemStyle}
        onClick={handleLogout}
      >
        Logout
      </MenuItem>
    </Menu>
  );

  return (
    <Box>
      <AppBar
        position="static"
        sx={{ backgroundColor: "#48255D" }}
        enableColorOnDark
        open={drawerOpen}
      >
        <Container maxWidth="lg">
          <Toolbar sx={{ paddingLeft: { xs: "0px" } }}>
            <Box sx={{ display: { xs: "flex", sm: "none" } }}>
              <IconButton
                size="large"
                aria-label="show more"
                aria-controls={menuId}
                aria-haspopup="true"
                onClick={handleProfileMenuOpen}
                color="inherit"
              >
                <MenuIcon />
              </IconButton>
            </Box>

            <Stack
              direction="row"
              spacing={1}
              sx={{
                alignItems: "center",
                marginLeft: { xs: "0", sm: "20px" },
                flexGrow: 1,
              }}
            >
              <IconButton
                size="large"
                edge="start"
                color="inherit"
                aria-label="open drawer"
                onClick={() => navigate("/")}
              >
                <img
                  style={{ width: 50 }}
                  src={image_logo}
                  alt="Divine Harmony"
                />
              </IconButton>

              <Typography
                sx={{
                  display: { xs: "none", sm: "block" },
                  fontFamily: "Open Sans, Medium",
                }}
              >
                {unauth ? "Divine Harmony" : title}
              </Typography>

              <Box sx={{ flexGrow: 1 }} />

              <Box sx={{ display: { xs: "none", sm: "block" } }}>
                <Button
                  style={menuItemStyle}
                  sx={{
                    color: currentPath === "/dashboard" ? "#FF827C" : "#FFF",
                  }}
                  onClick={() => navigate("/dashboard")}
                >
                  Home
                </Button>
                <Button
                  style={menuItemStyle}
                  sx={{
                    color: currentPath === "/courses" ? "#FF827C" : "#FFF",
                  }}
                  onClick={() => navigate("/courses")}
                >
                  Courses
                </Button>
                <Button
                  style={menuItemStyle}
                  sx={{
                    color: currentPath === "/shreekalp" ? "#FF827C" : "#FFF",
                  }}
                  onClick={() => navigate("/shreekalp")}
                >
                  Shreekalp
                </Button>
                <Button
                  style={menuItemStyle}
                  sx={{
                    color: currentPath === "/more" ? "#FF827C" : "#FFF",
                  }}
                  onClick={() => navigate("/more")}
                >
                  More
                </Button>
              </Box>
            </Stack>
          </Toolbar>
        </Container>
      </AppBar>
      {renderMenu}
    </Box>
  );
}
