import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Typography from "@mui/material/Typography";
import { useEffect, useState } from "react";
import { getUserKey, postData } from "../common/utils";
import { config } from "../env/config";
import { Box, Container } from "@mui/system";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";

import image_event_dh from "../assets/site/event_dh.png";
import { css } from "@emotion/css";
import { useNavigate } from "react-router";

const Courses = () => {
    const [expanded, setExpanded] = useState(false);
    const [eventPageDetails, setEventPageDetails] = useState([]);
    const navigate = useNavigate();

    const handleChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };

    useEffect(() => {
        fetchEventPageDetails();
    }, []);

    async function fetchEventPageDetails() {
        const userData = getUserKey();
        const data = await postData({
            url: `${config.serverUrl}/getEventPageCategoryDetails.php`,
            payload: {
                country_id: userData.country_id,
            },
            navigate,
            highlightError: true,
        });
        if (data) {
            setEventPageDetails(data);
        }
    }

    return (
        <Container maxWidth="lg" sx={{ mt: 3, mb: 4 }}>
            {eventPageDetails.map((event, index) => (
                <>
                    <Course
                        event={event}
                        handleChange={handleChange}
                        expanded={expanded}
                        index={index}
                    />
                    <Box
                        sx={{
                            width: "100%",
                            height: "1px",
                            background: "#d8d8d8",
                            margin: "auto",
                            marginBottom: "16px",
                        }}
                    />
                </>
            ))}
        </Container>
    );
};

const Course = ({ event, expanded, handleChange, index }) => {
    return (
        <Accordion
            sx={{
                marginBottom: "20px",
                boxShadow: "none",
            }}
            className={css({
                "&:before": {
                    height: "0px !important",
                    display: "none",
                },
            })}
            expanded={expanded === index}
            onChange={handleChange(index)}
        >
            <AccordionSummary
                expandIcon={expanded === index ? <RemoveIcon /> : <AddIcon />}
                aria-controls="panel1bh-content"
                id="panel1bh-header"
            >
                <Box
                    sx={{
                        display: "flex",
                        columnGap: "20px",
                        alignItems: "center",
                    }}
                >
                    <Box
                        component="img"
                        sx={{
                            width: `${expanded === index ? "150px" : "100px"}`,
                            height: `${expanded === index ? "120px" : "80px"}`,
                            borderRadius: "4px",
                        }}
                        alt="Dashboard - Landing Photo"
                        src={`${config.imgUrl}${event.imgPath}`}
                    />

                    <Box>
                        <Typography
                            sx={{
                                fontFamily: "Open Sans, Semibold",
                                fontSize: "16px",
                            }}
                        >
                            {event.title}
                        </Typography>

                        {event?.shortDesc && (
                            <Typography
                                sx={{
                                    fontFamily: "Open Sans, Regular",
                                    fontSize: "15px",
                                }}
                            >
                                {event.shortDesc}
                            </Typography>
                        )}
                    </Box>
                </Box>
            </AccordionSummary>

            <AccordionDetails>
                <Typography
                    sx={{
                        fontFamily: "Open Sans, Regular",
                        fontSize: "15px",
                        whiteSpace: "pre-wrap",
                    }}
                >
                    {event.description}
                </Typography>
            </AccordionDetails>
        </Accordion>
    );
};

export default Courses;
