import { config } from "../env/config";
import image_logo from "../assets/site/logo.png";

//function will get called when clicked on the pay button.
export async function displayRazorpayPaymentSdk({
    id,
    amount,
    apiKeyId,
    currency,
    description,
    email,
    name,
    mobile,
    transactionId,
    razorpayAmount,
    setShowLoader,
    onPaymentSuccess,
    successCb,
}) {
    const res = await loadRazorpayScript(
        "https://checkout.razorpay.com/v1/checkout.js"
    );

    if (!res) {
        if (setShowLoader) setShowLoader(false);
        alert("Razorpay SDK failed to load. please check are you online?");
        return;
    }
    setShowLoader(false);

    const options = {
        key: apiKeyId || config.razorAppKey,
        amount: razorpayAmount || amount * 100,
        currency: currency,
        name: "Divine Harmony",
        description: description,
        image: { image_logo },
        order_id: transactionId,
        // callback_url: "http://127.0.0.1:8000/razorpay_callback",
        // redirect: true,
        prefill: {
            name: name,
            email: email,
            contact: mobile,
        },
        handler: function (response) {
            if (
                response.razorpay_payment_id &&
                response.razorpay_order_id &&
                response.razorpay_signature
            ) {
                onPaymentSuccess({
                    ...response,
                    onlinePaymentId: id,
                    successCb,
                });
            } else {
                if (setShowLoader) setShowLoader(false);
            }
        },
        notes: {
            address: "",
        },
        theme: {
            color: "#61dafb",
        },
    };

    const paymentObject = new window.Razorpay(options);
    paymentObject.on("payment.failed", function (response) {
        if (setShowLoader) setShowLoader(false);
    });
    paymentObject.open();
}

//Function to load razorpay script for the display of razorpay payment SDK.
function loadRazorpayScript(src) {
    return new Promise((resolve) => {
        const script = document.createElement("script");
        script.src = src;
        script.onload = () => {
            resolve(true);
        };
        script.onerror = () => {
            resolve(false);
        };
        document.body.appendChild(script);
    });
}
