import * as React from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import FormHelperText from "@mui/material/FormHelperText";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import Stack from "@mui/material/Stack";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useNavigate } from "react-router";
import { toast } from "react-toastify";
import Geocode from "react-geocode";

import { config } from "../env/config";
import { fetchData, postData, readFileData } from "../common/utils";
import {
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    IconButton,
} from "@mui/material";
import image_user from "../assets/site/user.png";
import { css } from "@emotion/css";
import { getCountryList } from "../content/content";
import { alphabetRegex } from "../common/yup-utils";

const linkStyle = {
    textDecoration: "none",
    color: "#1976d2",
    fontSize: 14,
};
const selectComponentStyle = css({
    ".MuiFormLabel-root": {
        top: "-6px",
    },
});

var locationOptions = {
    enableHighAccuracy: true,
    timeout: 5000,
    maximumAge: 0,
};

const validationSchema = yup.object({
    firstName: yup
        .string()
        .required("Required")
        .matches(alphabetRegex, "Must be alphabets only"),
    lastName: yup
        .string()
        .required("Required")
        .matches(alphabetRegex, "Must be alphabets only"),
    email: yup.string().email().required("Required"),
    gender: yup.string().required("Required").nullable(),
    // whatsappNumber: yup  //turn on this validation if you face any problem in mobile number validation
    //   .string()
    //   .required("Please enter a valid mobile number")
    //   .matches(/^(\d{8}|\d{9}\d{10}|\d{11}|\d{12})$/, "Please enter a valid mobile number"),
    whatsappNumber: yup.lazy((value) =>
        value === ""
            ? yup.string().required("Required")
            : yup.string().when("country", ([selectedCountry], schema) => {
                  const countryListContent = getCountryList();
                  const selectedCountryData = countryListContent.find(
                      (country) => country.country_id === selectedCountry
                  );

                  return yup
                      .string()
                      .phone(
                          selectedCountryData
                              ? selectedCountryData.country_code
                              : ""
                      )
                      .required("Please enter a valid mobile number");
              })
    ),
    qualification: yup.string().required("Required"),
    occupation: yup.string().required("Required"),
    country: yup.string().required("Required").nullable(),
    state: yup.string().required("Required").nullable(),
    address: yup.string().required("Required"),
    age: yup.lazy((value) =>
        value === ""
            ? yup.string().required("Required")
            : yup
                  .number()
                  .typeError("Please enter a valid number")
                  .positive("Age must be a positive number")
                  .max(150, "Age should be less than 150 years.")
                  .integer("Age must be an integer")
                  .required("Required")
    ),
    referenceFrom: yup.string().required("Required"),
    password: yup
        .string()
        .required("Please enter your password.")
        .min(4, "Your password is too short.")
        .max(4, "Max 4 digits are allowed.")
        .matches(/^\d{4}$/, "Please enter numeric password"),
    confirmPassword: yup
        .string()
        .required("Please retype your password.")
        .oneOf([yup.ref("password")], "Your passwords do not match."),
    postCode: yup
        .string()
        .max(10, "Max 10 characters are allowed.")
        .required("Required"),
    policyCheck: yup
        .bool()
        .oneOf([true], "You need to accept the terms and conditions"),
    profilePhoto: yup
        .mixed()
        .test("required", "Please upload profile picture", (value) => {
            return value?.length > 0;
        })
        .test("fileSize", "The file is too large", (value) => {
            return value?.[0]?.size <= 2000000;
        }),
});

const defaultFormValues = {
    birthDate: new Date(),
    country: "",
    longitude: "",
    latitude: "",
};

export default function Registration() {
    const navigate = useNavigate();
    const countryList = getCountryList();
    const [registeredUserId, setRegisteredUserId] = React.useState(null);
    const [profileImageSrc, setProfileImageSrc] = React.useState(image_user);
    const [stateList, setStateList] = React.useState([]);
    const [isLocationAllowed, setIsLocationAllowed] = React.useState(true); // make it false by default when geo locaiton is corrected

    const onSubmit = (data) => {
        postRegistrationData(data, navigate);
    };
    const hasError = (element) => !!errors[element];
    const getError = (element) => {
        if (errors[element]) {
            return Object.values(errors[element])[0];
        }
    };

    const {
        register,
        handleSubmit,
        setValue,
        formState: { errors },
    } = useForm({
        defaultValues: defaultFormValues,
        resolver: yupResolver(validationSchema),
    });

    const getCurrentPosition = (
        successCb,
        errorCb = () => {},
        options = {}
    ) => {
        const locationData = navigator.geolocation.getCurrentPosition(
            successCb,
            errorCb,
            options
        );

        setIsLocationAllowed(true);
    };

    const locationSuccessCallback = (position) => {
        Geocode.fromLatLng(
            position.coords.latitude,
            position.coords.longitude
        ).then(
            (response) => {
                // const address = response.results[0].formatted_address;
                // const country = response?.results[0]?.address_components.find(
                //   (address) => address.types.includes("country")
                // );
                setValue("longitude", position.coords.longitude);
                setValue("latitude", position.coords.latitude);
            },
            (error) => {
                console.error(error);
            }
        );
    };

    const locationErrorCallback = (err) => {
        console.warn(`ERROR(${err.code}): ${err.message}`);
        toast.error("Please enable location access!");
    };

    const postRegistrationData = async (data, navigate) => {
        const {
            firstName,
            age,
            lastName,
            email,
            // middleName,
            // birthDate,
            gender,
            whatsappNumber,
            qualification,
            occupation,
            state,
            country,
            address,
            referenceFrom,
            profilePhoto,
            postCode,
            password,
            latitude,
            longitude,
        } = data;

        const profileData = await readFileData(profilePhoto);
        // let birthDateFormatted = new Date(birthDate).toLocaleString().split(",")[0];

        const payloadData = {
            fullname: firstName,
            lastname: lastName,
            dateofbirth: age,
            gender: gender,
            mobile: whatsappNumber,
            email: email,
            country_code: country,
            state_code: state,
            postCode: postCode,
            address: address,
            qualification: qualification,
            occupation: occupation,
            referralfrom: referenceFrom,
            password: password,
            profile_pic: profileData.base64,
            latitude: latitude || "0",
            longitude: longitude || "0",

            // middlename: middleName,
            // dateofbirth: birthDateFormatted,
            // district_code: "",
            // "profile_pic": profileData.base64
        };

        const res = await postData({
            url: `${config.serverUrl}/signup.php`,
            payload: payloadData,
            highlightError: true,
        });

        if (res) {
            setRegisteredUserId(res.member_id);
        }
    };

    const registedSuccessHandler = () => {
        navigate("/login");
        toast.success("Registration was successful. Please login to continue!");
    };

    // React.useEffect(() => {
    //   async function fetchList() {
    //     const countries = await fetchData(
    //       `${config.serverUrl}/getCountryList.php`
    //     );
    //     setCountryList(countries || []);
    //   }
    //   fetchList();
    // }, []);

    // React.useEffect(() => {
    //   Geocode.setApiKey(config.geoKey);
    //   Geocode.setLanguage("en");
    //   Geocode.setLocationType("ROOFTOP");
    //   Geocode.enableDebug();
    //   // Geocode.setRegion("es"); optional

    //   if (navigator.geolocation) {
    //     navigator.permissions
    //       .query({ name: "geolocation" })
    //       .then(function (result) {
    //         if (result.state === "granted") {
    //           getCurrentPosition(locationSuccessCallback);
    //         } else if (result.state === "prompt") {
    //           getCurrentPosition(
    //             locationSuccessCallback,
    //             locationErrorCallback,
    //             locationOptions
    //           );
    //         } else if (result.state === "denied") {
    //           toast.error("Please enable location access!");
    //         }
    //         result.onchange = function () {
    //           console.log(result.state);
    //         };
    //       });
    //   } else {
    //     toast.error(
    //       "Geolocation access is needed. Please allow location permission. Please try from a different browser if you can't find a way to allow location permission."
    //     );
    //     console.log("Geolocation is not available!");
    //   }
    // }, []);

    const handleCountryChange = async (evt) => {
        const payload = { country_id: evt.target.value };
        const states = await postData({
            url: `${config.serverUrl}/getStateList.php`,
            payload,
        });
        setStateList(states || []);
    };

    const profilePhotoHandle = register("profilePhoto");

    const handlePhotoUpload = async (event) => {
        const profileData = await readFileData(event.target.files);
        setProfileImageSrc(profileData?.base64 ?? image_user);
        profilePhotoHandle.onChange(event);
    };

    const handleFormSubmit = (event) => {
        if (isLocationAllowed) {
            handleSubmit(onSubmit)(event);
        } else {
            toast.error(
                "Location access is needed. Please enable location access!"
            );
        }
    };

    return (
        <Box
            sx={{
                height: "100%",
            }}
        >
            <Box
                sx={{
                    background: "#48255D",
                    height: "75px",
                }}
            >
                <Container
                    maxWidth="lg"
                    sx={{
                        display: "flex",
                        alignItems: "center",
                        color: "#FFFFFF",
                        height: "100%",
                    }}
                >
                    <IconButton
                        style={{
                            color: "white",
                        }}
                        aria-label="back"
                        onClick={() => window.history.go(-1)}
                    >
                        <ChevronLeftIcon />
                    </IconButton>

                    <Typography component="h1" variant="body1">
                        NEW USER SIGNUP
                    </Typography>
                </Container>
            </Box>

            <Container
                maxWidth="lg"
                component="main"
                sx={{
                    height: "100%",
                }}
            >
                <Box
                    component="form"
                    noValidate
                    onSubmit={handleSubmit(onSubmit)}
                    sx={{ mt: 3 }}
                >
                    <Grid
                        paddingBottom={4}
                        container
                        spacing={2}
                        rowGap={{ xs: 2, md: 4 }}
                    >
                        <Grid item xs={12} marginBottom={1}>
                            <Stack
                                spacing={3}
                                direction={{ xs: "column", md: "row" }}
                                alignItems="center"
                            >
                                <Box
                                    component="img"
                                    sx={{
                                        width: "100px",
                                        height: "100px",
                                        borderRadius: "50px",
                                    }}
                                    alt="User Photo"
                                    src={profileImageSrc}
                                />

                                <FormControl
                                    error={hasError("profilePhoto")}
                                    fullWidth
                                >
                                    <Stack
                                        spacing={{ xs: 1, md: 0 }}
                                        alignItems={{
                                            xs: "center",
                                            md: "flex-start",
                                        }}
                                    >
                                        <Button
                                            variant="outlined"
                                            component="label"
                                            sx={{
                                                textTransform: "none",
                                                color: "black",
                                                fontSize: "14px",
                                                fontFamily:
                                                    "Open Sans, Semibold",
                                                border: "1px dashed #A5A5A5",
                                            }}
                                        >
                                            Upload Your Photo
                                            <input
                                                type="file"
                                                onChange={handlePhotoUpload}
                                                ref={profilePhotoHandle.ref}
                                                hidden
                                                id="profilePhoto"
                                                name="profilePhoto"
                                            />
                                        </Button>
                                        <FormHelperText>
                                            {getError("profilePhoto")}
                                        </FormHelperText>
                                        <Typography
                                            component="p"
                                            variant="p"
                                            sx={{
                                                fontSize: 14,
                                                fontFamily:
                                                    "Open Sans, Regular",
                                            }}
                                            color="#898989"
                                        >
                                            (Photo size should be approximately
                                            2 MB)
                                        </Typography>
                                    </Stack>
                                </FormControl>
                            </Stack>
                        </Grid>

                        <Grid item xs={12} sm={6} md={4} lg={3}>
                            <FormControl
                                className={selectComponentStyle}
                                error={hasError("country")}
                                fullWidth
                            >
                                <InputLabel id="country">
                                    Select Country*
                                </InputLabel>
                                <Select
                                    labelId="country"
                                    id="country"
                                    {...register("country")}
                                    label="Select Country*"
                                    size="small"
                                    onChange={handleCountryChange}
                                    defaultValue=""
                                >
                                    {countryList.map((countryItem) => (
                                        <MenuItem
                                            value={countryItem.country_id}
                                            key={countryItem.country_id}
                                        >
                                            {countryItem.name}
                                        </MenuItem>
                                    ))}
                                </Select>

                                <FormHelperText>
                                    {getError("country")}
                                </FormHelperText>
                            </FormControl>
                        </Grid>

                        <Grid item xs={12} sm={6} md={4} lg={3}>
                            <TextField
                                autoComplete="given-name"
                                name="firstName"
                                {...register("firstName")}
                                error={hasError("firstName")}
                                helperText={getError("firstName")}
                                size="small"
                                fullWidth
                                id="firstName"
                                label="First Name*"
                                autoFocus
                            />
                        </Grid>

                        {/* <Grid item xs={12} sm={6} md={4} lg={3}>
              <TextField
                autoComplete="middle-name"
                name="middleName"
                {...register("middleName")}
                error={hasError("middleName")}
                helperText={getError("middleName")}
                size="small"
                fullWidth
                id="middleName"
                label="Middle Name"
                autoFocus
              />
            </Grid> */}

                        <Grid item xs={12} sm={6} md={4} lg={3}>
                            <TextField
                                autoComplete="last-name"
                                name="lastName"
                                {...register("lastName")}
                                error={hasError("lastName")}
                                helperText={getError("lastName")}
                                size="small"
                                fullWidth
                                id="lastName"
                                label="Last Name*"
                                autoFocus
                            />
                        </Grid>

                        <Grid item xs={12} sm={6} md={4} lg={3}>
                            <TextField
                                autoComplete="age"
                                name="age"
                                {...register("age")}
                                error={hasError("age")}
                                helperText={getError("age")}
                                size="small"
                                fullWidth
                                id="age"
                                label="Age*"
                                autoFocus
                            />
                        </Grid>

                        {/* <Grid item xs={12} sm={6} md={4} lg={3}>
              <FormControl error={hasError("birthDate")} fullWidth>
                <Controller
                  name="birthDate"
                  id="birthDate"
                  control={control}
                  render={({ field }) => (
                    <DatePicker
                      {...field}
                      label="Date of Birth"
                      fullWidth
                      autoFocus
                      renderInput={(params) => (
                        <TextField size="small" fullWidth {...params} />
                      )}
                    />
                  )}
                />
                <FormHelperText>{getError("birthDate")}</FormHelperText>
              </FormControl>
            </Grid> */}

                        <Grid item xs={12} sm={6} md={4} lg={3}>
                            <FormControl
                                className={selectComponentStyle}
                                error={hasError("gender")}
                                fullWidth
                            >
                                <InputLabel id="gender">
                                    Select Gender*
                                </InputLabel>
                                <Select
                                    labelId="gender"
                                    id="gender"
                                    {...register("gender")}
                                    label="Select Gender*"
                                    size="small"
                                    autoComplete="gender"
                                    autoFocus
                                    defaultValue=""
                                >
                                    <MenuItem value="male">Male</MenuItem>
                                    <MenuItem value="female">Female</MenuItem>
                                    <MenuItem value="other">Other</MenuItem>
                                </Select>
                                <FormHelperText>
                                    {getError("gender")}
                                </FormHelperText>
                            </FormControl>
                        </Grid>

                        <Grid item xs={12} sm={6} md={4} lg={3}>
                            <TextField
                                autoComplete="whatsapp-number"
                                name="whatsappNumber"
                                {...register("whatsappNumber")}
                                error={hasError("whatsappNumber")}
                                helperText={getError("whatsappNumber")}
                                size="small"
                                fullWidth
                                id="whatsappNumber"
                                label="Mobile Number*"
                                autoFocus
                            />
                        </Grid>

                        <Grid item xs={12} sm={6} md={4} lg={3}>
                            <TextField
                                autoComplete="email"
                                name="email"
                                {...register("email")}
                                error={hasError("email")}
                                helperText={getError("email")}
                                size="small"
                                fullWidth
                                id="email"
                                label="Email ID*"
                                autoFocus
                            />
                        </Grid>

                        <Grid item xs={12} sm={6} md={4} lg={3}>
                            <FormControl
                                className={selectComponentStyle}
                                error={hasError("state")}
                                fullWidth
                            >
                                <InputLabel id="state">
                                    State/Province/Region*
                                </InputLabel>
                                <Select
                                    labelId="state"
                                    id="state"
                                    {...register("state")}
                                    label="State/Province/Region*"
                                    size="small"
                                    autoComplete="state"
                                    autoFocus
                                    defaultValue=""
                                >
                                    {stateList.map((stateItem) => (
                                        <MenuItem
                                            value={stateItem.zone_id}
                                            key={stateItem.zone_id}
                                        >
                                            {stateItem.name}
                                        </MenuItem>
                                    ))}
                                </Select>
                                <FormHelperText>
                                    {getError("state")}
                                </FormHelperText>
                            </FormControl>
                        </Grid>

                        <Grid item xs={12} sm={6} md={4} lg={3}>
                            <TextField
                                autoComplete="postCode"
                                name="postCode"
                                {...register("postCode")}
                                error={hasError("postCode")}
                                helperText={getError("postCode")}
                                size="small"
                                fullWidth
                                id="postCode"
                                label="ZIP/Postal Code*"
                                autoFocus
                            />
                        </Grid>

                        <Grid item xs={12} sm={6} md={4} lg={3}>
                            <TextField
                                autoComplete="address"
                                name="address"
                                {...register("address")}
                                error={hasError("address")}
                                helperText={getError("address")}
                                size="small"
                                fullWidth
                                id="address"
                                label="Address line*"
                                autoFocus
                                defaultValue=""
                            />
                        </Grid>

                        <Grid item xs={12} sm={6} md={4} lg={3}>
                            <TextField
                                autoComplete="qualification"
                                name="qualification"
                                {...register("qualification")}
                                error={hasError("qualification")}
                                helperText={getError("qualification")}
                                size="small"
                                fullWidth
                                id="qualification"
                                label="Qualification*"
                                autoFocus
                            />
                        </Grid>

                        <Grid item xs={12} sm={6} md={4} lg={3}>
                            <TextField
                                autoComplete="occupation"
                                name="occupation"
                                {...register("occupation")}
                                error={hasError("occupation")}
                                helperText={getError("occupation")}
                                size="small"
                                fullWidth
                                id="occupation"
                                label="Occupation*"
                                autoFocus
                            />
                        </Grid>

                        <Grid item xs={12} sm={6} md={4} lg={3}>
                            <TextField
                                autoComplete="reference-from"
                                name="referenceFrom"
                                {...register("referenceFrom")}
                                error={hasError("referenceFrom")}
                                helperText={getError("referenceFrom")}
                                size="small"
                                fullWidth
                                id="referenceFrom"
                                label="Referral From*"
                                autoFocus
                            />
                        </Grid>

                        <Grid item xs={12} sm={6} md={4} lg={3}>
                            <TextField
                                name="password"
                                {...register("password")}
                                error={hasError("password")}
                                helperText={getError("password")}
                                type="password"
                                size="small"
                                fullWidth
                                id="password"
                                label="4 Digit Password*"
                                autoFocus
                            />
                        </Grid>

                        <Grid item xs={12} sm={6} md={4} lg={3}>
                            <TextField
                                name="confirmPassword"
                                {...register("confirmPassword")}
                                error={hasError("confirmPassword")}
                                helperText={getError("confirmPassword")}
                                type="password"
                                size="small"
                                fullWidth
                                id="confirmPassword"
                                label="Confirm 4 Digit Password*"
                                autoFocus
                            />
                        </Grid>

                        <Grid item xs={12}>
                            <Button
                                target="_blank"
                                sx={{
                                    textTransform: "none",
                                    fontSize: "14px",
                                    color: "#0062FF",
                                    marginLeft: "0px",
                                    width: "fit-content",
                                }}
                                href="https://global.eonlineworkshop.com/g_privacypolicy_refund.html"
                            >
                                Privacy Policy
                            </Button>
                            <Button
                                target="_blank"
                                sx={{
                                    textTransform: "none",
                                    fontSize: "14px",
                                    color: "#0062FF",
                                    marginLeft: "0px",
                                    width: "fit-content",
                                }}
                                href="https://global.eonlineworkshop.com/g_termsnconditions.html"
                            >
                                Terms & Conditions
                            </Button>
                            <Stack
                                direction={{ xs: "column", md: "row" }}
                                spacing={1}
                                justifyContent="space-between"
                                alignItems={{ xs: "left", md: "center" }}
                            >
                                <FormControl
                                    sx={{
                                        flex: "1",
                                    }}
                                    error={hasError("policyCheck")}
                                    fullWidth
                                >
                                    <Box
                                        sx={{
                                            display: "flex",
                                            flexDirection: "column",
                                        }}
                                    >
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    value=""
                                                    color="primary"
                                                    id="policyCheck"
                                                    name="policyCheck"
                                                    {...register("policyCheck")}
                                                />
                                            }
                                            label={
                                                <Typography
                                                    component="p"
                                                    sx={{
                                                        fontSize: 14,
                                                        fontFamily:
                                                            "Open Sans, Regular",
                                                    }}
                                                    color="#9D08C2"
                                                >
                                                    I accept Terms & Conditions
                                                </Typography>
                                            }
                                        />
                                    </Box>
                                    <FormHelperText sx={{ marginLeft: "30px" }}>
                                        {getError("policyCheck")}
                                    </FormHelperText>
                                </FormControl>
                                <Box
                                    sx={{
                                        width: { xs: "100%", md: "auto" },
                                        flex: "1",
                                        display: "flex",
                                        justifyContent: "flex-end",
                                    }}
                                >
                                    <Button
                                        type="button"
                                        variant="contained"
                                        size="small"
                                        sx={{
                                            mr: 1,
                                            mt: { xs: 2, md: 0 },
                                            background: "#F8F8F8",
                                            color: "#000000",
                                            width: { xs: "100%", md: "220px" },
                                            height: "50px",
                                        }}
                                        onClick={() => window.history.go(-1)}
                                    >
                                        BACK
                                    </Button>
                                    <Button
                                        type="button"
                                        onClick={handleFormSubmit}
                                        variant="contained"
                                        size="small"
                                        sx={{
                                            mt: { xs: 2, md: 0 },
                                            background: "#F67800",
                                            width: { xs: "100%", md: "220px" },
                                            height: "50px",
                                        }}
                                    >
                                        SUBMIT
                                    </Button>
                                </Box>
                            </Stack>
                        </Grid>
                    </Grid>

                    <SuccessDialog
                        userId={registeredUserId}
                        open={registeredUserId ? true : false}
                        onClick={registedSuccessHandler}
                    />
                </Box>
            </Container>
        </Box>
    );
}

const SuccessDialog = ({ userId, open, onClick }) => {
    const [show, setShow] = React.useState(false);

    React.useEffect(() => {
        setShow(open);
    }, [open]);

    const handleClose = () => {
        setShow(false);
    };

    return (
        <Dialog open={open} onClose={handleClose}>
            <DialogTitle id="alert-dialog-title">
                Registration successful
            </DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    Please note that your user ID is {userId || "----"}. Use
                    this user id and the password that you have set during
                    registration to login in the application.
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={onClick} autoFocus>
                    Ok
                </Button>
            </DialogActions>
        </Dialog>
    );
};
