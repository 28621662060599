import React from "react";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import { Card, CardContent, Grid } from "@mui/material";

import balanced_mind from "../../assets/site/landing/balanced_mind.webp";
import chakra_balancing from "../../assets/site/landing/chakrabalancing.webp";
import gratitudeImg from "../../assets/site/landing/gratitudeisg.webp";

import about_dh_background from "../../assets/site/landing/about_divine_harmony.webp";
import design_heading from "../../assets/site/landing/design_heading.webp";
import divinity_bg from "../../assets/site/landing/divinity_bg.webp";
import benefits_bg from "../../assets/site/landing/benefits_bg.mp4";
import { ContactUs } from "../components/ContactUs";
import { Header } from "../components/Header";
import { BackgroundImg } from "../components/BackgroundImg";
import {
    ContentList,
    ContentText,
    MediaBackgroundImage,
    MediaBackgroundVideo,
    MediaImage,
    SecondaryHeading,
    SectionHeading,
} from "../../common/typography";

const AboutDH = () => (
    <Grid item xs={12}>
        <div style={{ textAlign: "center" }}>
            <MediaImage
                src={design_heading}
                sx={{
                    width: { xs: 230, md: 275 },
                    height: { xs: 26, md: 35 },
                }}
            />
        </div>

        <Container maxWidth="md" sx={{ pb: 5, background: "#ffffff4f" }}>
            <SectionHeading>about divine harmony</SectionHeading>
            <SecondaryHeading>
                Divine Harmony is a journey of rediscovering human intelligence.
            </SecondaryHeading>
            <ContentText bold>
                Divine Harmony with Shreekalp works on your physical, mental &
                emotional level. Due to which your body stays Fit (a
                disease-free body), the mind remains calm and your emotions get
                pure. The capacity of your mind and intellect get enhanced and
                your uniqueness is revealed to the world.
            </ContentText>
            <ContentText bold>
                It is not any yoga, meditation, or motivational event, it is an
                Energy-based therapy to be experienced. The uniqueness of
                program is that you need not have to practice anything at home
                post-therapy. You need not have to change your daily routine or
                lifestyle post-therapy, but gradually you transform from inside
                and feel energetic, gain mastery over your thoughts and
                emotions. It will filled up your life with positivity, purity
                and divinity which is essential for permanent happiness
            </ContentText>
        </Container>
    </Grid>
);

const EliminationOfNegativity = () => (
    <Grid item xs={12}>
        <Container maxWidth="md" sx={{ mt: 3, mb: 4, pb: 4 }}>
            <Card sx={{ minWidth: 275 }}>
                <CardContent>
                    <SectionHeading color="#0966B8">
                        ELIMINATION OF NEGATIVITY
                    </SectionHeading>
                    <SecondaryHeading color="#CB3DD9">
                        Let's Inhale confidence, exhale doubts...
                    </SecondaryHeading>
                    <Box
                        sx={{
                            display: "flex",
                            flexDirection: { xs: "column", md: "row" },
                            alignItems: "center",
                            marginTop: "30px",
                            marginBottom: "20px",
                        }}
                    >
                        <Box
                            sx={{
                                width: { xs: "auto", sm: 400 },
                                display: "flex",
                                flexDirection: "column",
                                rowGap: "20px",
                            }}
                        >
                            <ContentText>
                                We, a beautiful creativity of God. God sends us
                                to the earth in a pure and divine form but
                                because of our own ignorance, careless-ness,
                                negligence, we turn ourselves in to impure form.
                            </ContentText>
                            <ContentText>
                                Since from childhood, most of the time we face
                                failure, carry our past mistakes, unfairness
                                towards ourselves and our family, incomplete
                                desires, inefficiency.
                            </ContentText>
                            <ContentText>
                                Because of all these factors we start becoming
                                negative towards life. Negativity overpower us
                                and we star draining our energy. Due to this
                                negativity we live a stressful life, our future
                                planning go wrong and our mind and body gives up
                                at a certain point. By living this type of
                                stressful life we invite many diseases, which
                                weakens our strength from inside. Though we
                                pretend to be healthy, our body reveals the
                                truth and our life becomes the headquarter of
                                TENSION, DEPRESSION, STRESS, MISERY and SADNESS.
                            </ContentText>
                        </Box>
                        <MediaImage
                            src={balanced_mind}
                            sx={{
                                width: { xs: 250, md: 354 },
                                height: { xs: 298, md: 422 },
                            }}
                        />
                    </Box>
                    <ContentText>
                        We crave for HAPPINESS AND PEACE. We start searching
                        them outside. It's time to take charge of our own BODY,
                        MIND and SOUL. Let's kick out all the negativity and
                        fill up our life with purity and positivity. EXTERNAL
                        STIMULATION SHOULD NEVER TRIGGER OUR INNER PEACE. Our
                        peace of mind and happiness is an inside job. Divine
                        Harmony provides a platform for the same, it helps you
                        to get back to your pure form. Along with us you can
                        walk on the path of Divinity. Come join us on the Divine
                        journey.
                    </ContentText>
                </CardContent>
            </Card>
        </Container>
    </Grid>
);

const ChakraBalancing = () => (
    <Grid item xs={12}>
        <Container maxWidth="md" sx={{ mt: 3, mb: 4, pb: 4 }}>
            <Card sx={{ minWidth: 275 }}>
                <CardContent style={{ textAlign: "center" }}>
                    <SectionHeading color="#0966B8">
                        Chakra Balancing
                    </SectionHeading>

                    <MediaImage
                        src={chakra_balancing}
                        sx={{
                            width: { xs: "100%", md: 600 },
                            height: { xs: 154, md: 328 },
                        }}
                    />

                    <Box textAlign="justify">
                        <ContentText>
                            Important decisions of life are always to be taken
                            wisely, calmly and meaningfully. For this we need to
                            have a strong and balanced mindset. Our mental state
                            should be peaceful and stable. Our mind and mindset
                            depend upon the 'chakras'. More the chakras are
                            activated and balanced more is the capacity to be
                            mentally and physically strong. Each and every
                            decision of our life should be executive,
                            responsible, mature and organized; then only we can
                            step forward in a progressive manner.
                        </ContentText>
                        <ContentText>
                            Our decisions play important role in our lives, may
                            be its financial, social or personal. To create
                            balance between our chakras, healthy mind and mental
                            soundness, Divine Harmony is a solution. We, at
                            divine harmony make you walk hand in hand towards
                            peaceful, balanced and Divine life.
                        </ContentText>
                    </Box>
                </CardContent>
            </Card>
        </Container>
    </Grid>
);
const Gratitude = () => (
    <Grid item xs={12}>
        <Container maxWidth="md" sx={{ mt: 3, mb: 4, pb: 4 }}>
            <Card sx={{ minWidth: 275 }}>
                <CardContent
                    style={{
                        textAlign: "center",
                        background: "#fffae3",
                        padding: "20px",
                    }}
                >
                    <SectionHeading color="#8B0000">
                        GRATITUDE FOR THE DIVINE ENERGY
                    </SectionHeading>

                    <Box
                        sx={{
                            display: "flex",
                            columnGap: "15px",
                            flexDirection: { xs: "column", md: "row" },
                            alignItems: "center",
                            textAlign: { xs: "center", md: "left" },
                            rowGap: "15px",
                        }}
                    >
                        <MediaImage
                            src={gratitudeImg}
                            sx={{
                                width: { xs: 250, md: 362 },
                                height: { xs: 250, md: 310 },
                            }}
                        />
                        <Box
                            style={{
                                display: "flex",
                                flexDirection: "column",
                                rowGap: "15px",
                            }}
                        >
                            <ContentText bold>
                                You can't live a positive life with a negative
                                mind...
                            </ContentText>
                            <ContentText>
                                We all are more or less emotional. Some of us
                                are in charge of their emotions but some of us
                                take everything to their heart.
                            </ContentText>
                            <ContentText>
                                At times, we make our decisions in emotional
                                state. Emotional decisions can't always go right
                                specially if it's been taken in our profession,
                                because professionally we need to be practical.
                            </ContentText>
                            <ContentText>
                                Emotional decisions if gets mixed up with
                                practical ones, it may lead to serious damage
                                sometimes and we may regret it till our life.
                                But no matter how much we try not to be heart
                                rending but situations make us to do that.
                                Emotions need to be render at right time. To get
                                a control over our emotions, we need to get pure
                                and get connected to positive and divine energy.
                                Emotions should flow in a pure and right
                                direction.
                            </ContentText>
                        </Box>
                    </Box>
                </CardContent>
            </Card>
        </Container>
    </Grid>
);

const Divinity = () => (
    <Grid item xs={12} style={{ position: "relative" }}>
        <MediaBackgroundImage src={divinity_bg} />

        <Container maxWidth="md" sx={{ pb: 5, pt: 5 }}>
            <SectionHeading color="#80068C">DIVINITY</SectionHeading>
            <ContentText>
                We at Divine Harmony, empower you with purity and Divinity.
            </ContentText>
            <ContentText>
                By maintaining physical, mental and emotional balance one can
                achieve totalness. A Divine person upgrades his spiritual level
                by maintaining physical, mental and emotional balance. There are
                many complications at the primary stage of life. We get
                entangled in the first three levels of our life, so much that we
                do not arose from that particular stage. Our physical need is
                our first level, where we all require air, water, food, sleep
                and sex. When these needs are not satisfied, we feel sick,
                irritation, pain and some type of discomfort.
            </ContentText>
            <ContentText>
                Second is safety need, it focuses stability and consistency for
                ourselves in the outside world. We feel unsafe if this need is
                not fulfilled.
            </ContentText>
            <ContentText>
                Third is need of love, human is a social animal and has a strong
                desire for love, for this they belong to work group, religious
                group, families and friends. All these make them feel needed and
                loved.
            </ContentText>
            <ContentText>
                Now a normal person, get so much engrossed in these stages all
                his life, that he completes his life circle here itself
                fulfilling these levels, but above this there are higher levels
                too, which one needs to unfold. There are people who are above
                all these basic levels. If we go through their biography, we can
                see the profoundness, the totality, the depth, the higher
                thinking power of their life. They think beyond our
                imaginations. Their life style is at such a high level, which we
                don't even think of nor try to understand Divine Harmony, tries
                to unfold your higher level which gives soundness to your life,
                even you can reach to that level. All you need to do is come and
                join us and attain higher’ divine journey.
            </ContentText>
        </Container>
    </Grid>
);
const Benefits = () => (
    <Grid item xs={12} mb={2} style={{ position: "relative" }}>
        <MediaBackgroundVideo src={benefits_bg} />

        <Container maxWidth="md" sx={{ pb: 5, pt: 5 }}>
            <SectionHeading color="#FF94EF">
                benefits of divine harmony
            </SectionHeading>
            <ContentText color="#FFF">
                One who lives in harmony with himself, lives in harmony with
                universe. Divine harmony benefits you with multiple
                possibilities. You learn to be in alignment with your divine
                journey of discovery and in harmony with your emotions. It
                teaches you how to get rid of all negativities & make your life
                rhythmic and soundful.
            </ContentText>
            <ContentText color="#FFF">
                When you start your divine journey, you can strengthen your
                efforts and learn to control your emotions due to which you can
                speed up your progress. You can maintain the graph of your
                progress with balanced life, controlled emotions & poised
                behavior and you can grow spiritually and attain purpose of your
                life.
            </ContentText>
            <ContentList
                textItems={[
                    "Increases energy efficiency",
                    "It's a Divine growth of mind and soul",
                    "It gives peace of mind",
                    "It's a stress relief mechanism",
                    "Helps to improve your physical and mental health",
                ]}
            />
        </Container>
    </Grid>
);

const PublicAboutUs = () => {
    return (
        <div>
            <Box>
                <Header currentPage="aboutus" />
                <Grid container spacing={{ xs: 1, md: 3 }}>
                    <Grid item xs={12} mb={3}>
                        <MediaImage
                            src={about_dh_background}
                            sx={{
                                width: "100%",
                                height: { xs: 200, md: 340 },
                            }}
                        />
                    </Grid>
                    <AboutDH />
                    <EliminationOfNegativity />
                    <ChakraBalancing />
                    <Gratitude />
                    <Divinity />
                    <Benefits />
                    <ContactUs />
                </Grid>
            </Box>
            <BackgroundImg />
        </div>
    );
};

export default PublicAboutUs;
