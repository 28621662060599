import { Button } from "@mui/material";

import {
    DialogContent,
    Dialog,
    DialogTitle,
    DialogContentText,
    DialogActions,
} from "@mui/material";

export const WhatsappJoinDialog = ({
    show,
    primaryMsg,
    secondaryMsg,
    whatsappLink,
    onCloseHandler,
}) => {
    return (
        <Dialog open={show} onClose={onCloseHandler}>
            <DialogTitle
                sx={{ color: "#3f51b5", textAlign: "center", fontSize: "22px" }}
            >
                Join Whatsapp Group
            </DialogTitle>
            <DialogContent>
                <DialogContentText
                    sx={{
                        fontSize: { xs: "15px", md: "18px" },
                        whiteSpace: "pre-wrap",
                        color: "#263238",
                    }}
                    fontFamily="Open Sans, Semibold"
                >
                    {primaryMsg}
                </DialogContentText>
                <br />
                <DialogContentText
                    sx={{
                        fontSize: { xs: "15px", md: "18px" },
                        whiteSpace: "pre-wrap",
                        color: "#263238",
                    }}
                    fontFamily="Open Sans, Semibold"
                >
                    {secondaryMsg}
                </DialogContentText>
            </DialogContent>
            <DialogActions sx={{ justifyContent: "center" }}>
                <Button
                    size="small"
                    variant="contained"
                    onClick={onCloseHandler}
                    sx={{ marginRight: "10px", background: "#78909c" }}
                >
                    Cancel
                </Button>
                <Button
                    size="small"
                    variant="contained"
                    href={whatsappLink}
                    onClick={onCloseHandler}
                    target="_blank"
                    sx={{
                        marginRight: "10px",
                        background: "#29b6f6",
                        width: "100px",
                    }}
                >
                    Join
                </Button>
            </DialogActions>
        </Dialog>
    );
};
