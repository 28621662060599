import Typography from "@mui/material/Typography";
import { Box, Container } from "@mui/system";

import image_shreekalp from "../assets/site/shreekalp.png";
import { Paper } from "@mui/material";

const benefitsList = [
  "Stress Release",
  "Increase Energy Efficiency",
  "Permanent Welbeing",
  "Awaken Inner Potential",
  "Discover the Inner Peace",
  "Permanent Happiness",
  "Know your Life",
];
const Shreekalp = () => {
  return (
    <Box>
      <Box
        sx={{ background: "#F8F9FF", padding: { xs: "10px 0", md: "40px 0" } }}
      >
        <Container
          maxWidth="lg"
          sx={{
            padding: { xs: "0 10px", md: "0px 16px" },
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: { xs: "column", md: "row" },
              columnGap: "20px",
            }}
          >
            <Box
              component="img"
              sx={{
                width: { xs: "100%", md: "300px" },
                height: { xs: "100%", md: "420px" },
                borderRadius: "2px",
              }}
              alt="Shreekalp Photo"
              src={image_shreekalp}
            />

            <Paper
              elevation={0}
              sx={{
                padding: { xs: "25px 10px 10px 10px", md: "30px 80px" },
              }}
              style={{
                width: "100%",
                borderRadius: "15px",
              }}
            >
              <Typography
                component="p"
                variant="p"
                sx={{
                  fontSize: { xs: "15px", md: "20px" },
                  fontFamily: "Open Sans, Semibold",
                  fontWeight: "700",
                  textAlign: "center",
                  marginBottom: "25px",
                }}
                color="#48255D"
              >
                What will you get from Shreekalp?
              </Typography>
              <Box
                sx={{
                  display: "grid",
                  gridTemplateColumns: "1fr 1fr",
                  gridColumnGap: "20px",
                }}
              >
                {benefitsList.map((benefit) => (
                  <Box
                    sx={{
                      background: "#DDCEFF",
                      width: "100%",
                      height: "60px",
                      marginBottom: "15px",
                      borderRadius: "11px",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <Typography
                      component="p"
                      variant="p"
                      sx={{ fontSize: 14, fontFamily: "Open Sans, Regular" }}
                      color="#48255D"
                    >
                      {benefit}
                    </Typography>
                  </Box>
                ))}
              </Box>
            </Paper>
          </Box>
        </Container>
      </Box>

      <Box style={{ textAlign: "center", padding: "30px 0" }}>
        <Container maxWidth="lg">
          <Typography
            component="p"
            variant="p"
            sx={{
              fontSize: { xs: "16px", md: "24px" },
              fontFamily: "Open Sans, Semibold",
            }}
          >
            About Shreekalp
          </Typography>
          <Box
            sx={{
              width: "90px",
              height: "5px",
              background: "#f5f0e3",
              margin: "auto",
              marginBottom: { xs: "5px", md: "16px" },
            }}
          />
          <Typography
            component="p"
            variant="p"
            sx={{ fontSize: 14, fontFamily: "Open Sans, Medium" }}
            mb={5}
          >
            Your Companion, Mentor & Guide for Life
          </Typography>

          <Box
            sx={{
              fontSize: 14,
              fontFamily: "Open Sans, Regular",
              textAlign: "left",
            }}
            mb={5}
          >
            <Typography component="p" variant="p" mb={2}>
              Shreekalp is a contemporary spiritual mentor. He has an art of
              human energy transformation and mastery over energy balancing by
              removing the negative energy from our inner system which will
              immensely increase our potential.
            </Typography>
            <Typography component="p" variant="p" mb={2}>
              Shreekalp is the creator of Divine Harmony, an energy enhancement
              therapy, through which he guides as a companion and mentor for
              ultimate self liberation. In divine harmony session your inner
              system get nourishment.
            </Typography>
            <Typography component="p" variant="p" mb={2}>
              ​Shreekalp is blessed with a great ability of energy reading which
              helps us to resolve our life issues by his accurate guidance.
              Being a modern monk Shreekalp understand the demand of current
              time and lifestyle. In todays competitive environment we all are
              so busy in achieving our goals and struggling for time management,
              work life balance etc. and that’s why he has invented a unique
              simplified form of spirituality which is very easy to adopt as it
              doesn’t require any daily meditation practice to follow and yet we
              can get all the benefits of meditations. His unique style of
              spirituality make our life simple and stress free.
            </Typography>
            <Typography component="p" variant="p" mb={2}>
              ​Shreekalp can transform your energies and rendezvous yourself
              with real you.​Shreekalp had interest in spirituality from an
              early childhood and gives spiritual guidance and discourses. In
              his companionship one can experience love, knowledge and happiness
              of mankind to do spiritual celebration of life. Along with his
              spiritual interest Shreekalp has love for social activities for
              uplifting the under-privileged people of the society.​
            </Typography>

            <Typography component="p" variant="p" mb={2}>
              Shreekalp has an ability to understand human emotions and has art
              to visualize energy patterns of people. Based on that he can
              suggest how to maintain energy with in a person, and how to
              develop it for giving a meaning to the individual life. He has
              creativity to tailor expectations, dreams, goals and views; he can
              even customize them as per the interest of the individual.
            </Typography>
          </Box>
        </Container>
      </Box>
    </Box>
  );
};

export default Shreekalp;
