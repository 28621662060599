import React, { useEffect, useState } from "react";
import { Button, Grid, Typography } from "@mui/material";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";

import { DownloadReceipt } from "../DownloadReceipt";
import { getUserKey, postData } from "../../common/utils";
import { config } from "../../env/config";
import { WhatsappJoinDialog } from "../../dashboard/WhatsappJoinDialog";

export const UserPaymentHistory = () => {
    const [userPaymentHistory, setUserPaymentHistory] = useState([]);
    const userData = getUserKey();
    const payload = {
        user_id: userData.id,
        member_id: userData.member_id,
        country_id: userData.country_id,
    };

    useEffect(() => {
        async function fetchData() {
            const data = await postData({
                url: `${config.serverUrl}/getUserEventHistory.php`,
                payload,
            });
            setUserPaymentHistory(data);
        }
        fetchData();
    }, []);

    return (
        <>
            <Grid container spacing={2} mt={5}>
                <Grid item md={12}>
                    <Typography
                        component="p"
                        variant="p"
                        sx={{ fontSize: 18, fontFamily: "Open Sans, Semibold" }}
                    >
                        Payment History
                    </Typography>
                </Grid>

                {userPaymentHistory ? (
                    userPaymentHistory?.map(
                        ({
                            event_type,
                            eventdate,
                            event_for,
                            groupLink,
                            receiptUrl,
                        }) => (
                            <Grid item md={6}>
                                <PaymentItem
                                    title={event_type}
                                    date={eventdate}
                                    mode={event_for}
                                    whatsappLink={groupLink}
                                    url={receiptUrl}
                                />
                            </Grid>
                        )
                    )
                ) : (
                    <Grid item>
                        <Typography
                            component="p"
                            variant="p"
                            sx={{
                                fontSize: 14,
                                fontFamily: "Open Sans, Semibold",
                            }}
                        >
                            No payment history present.
                        </Typography>
                    </Grid>
                )}
            </Grid>
        </>
    );
};

const PaymentItem = ({ title, date, mode, url, whatsappLink }) => {
    const [showWhatsappDialog, setShowWhatsappDialog] = React.useState(false);

    return (
        <>
            <Grid
                container
                sx={{
                    background: "#F8F9FF",
                    borderRadius: "6px",
                    padding: "12px 16px",
                    alignItems: "center",
                    width: "100%",
                }}
            >
                <Grid item xs={12} mb={2}>
                    <Typography
                        component="p"
                        variant="p"
                        sx={{
                            fontSize: 18,
                            fontFamily: "Open Sans, Medium",
                            color: "#48255D",
                        }}
                    >
                        {title}
                    </Typography>
                </Grid>

                <Grid item xs={6}>
                    <Typography
                        component="p"
                        variant="p"
                        sx={{
                            fontSize: 13,
                            fontFamily: "Open Sans, Regular",
                            color: "#48255D",
                        }}
                    >
                        Date: {date}
                    </Typography>
                </Grid>

                <Grid item xs={6}>
                    <Typography
                        component="p"
                        variant="p"
                        sx={{
                            fontSize: 13,
                            fontFamily: "Open Sans, Regular",
                            color: "#64B443",
                            alignItems: "center",
                            display: "flex",
                            paddingLeft: "5px",
                        }}
                    >
                        <CheckCircleOutlineIcon />
                        Booked
                    </Typography>
                </Grid>

                <Grid item xs={6}>
                    <Typography
                        component="p"
                        variant="p"
                        sx={{
                            fontSize: 13,
                            fontFamily: "Open Sans, Regular",
                            color: "#48255D",
                        }}
                    >
                        Mode: {mode}
                    </Typography>
                </Grid>

                <Grid item xs={6}>
                    <DownloadReceipt url={url} />
                </Grid>

                {whatsappLink ? (
                    <>
                        <Grid xs={6} item></Grid>
                        <Grid xs={6} item>
                            <Button
                                variant="text"
                                size="small"
                                onClick={() => setShowWhatsappDialog(true)}
                                sx={{
                                    height: "40px",
                                    fontSize: 13,
                                    fontFamily: "Open Sans, Regular",
                                    color: "#48255D",
                                }}
                            >
                                <WhatsAppIcon />
                                &nbsp; Join Whatsapp Group
                            </Button>
                        </Grid>
                    </>
                ) : null}

                {/* Show join whatsapp button when update response contains whatsappLink */}
            </Grid>
            {whatsappLink && showWhatsappDialog ? (
                <WhatsappJoinDialog
                    show={showWhatsappDialog}
                    primaryMsg={`Event: ${title} \r\nDate: ${date} \r\n\nKindly click on below link to join the whatsapp group for this event.`}
                    secondaryMsg={`Happy to help you!\r\n\r\nThanks & Regards,\r\nDivine Harmony Team.\r\n+918169770092`}
                    whatsappLink={whatsappLink}
                    onCloseHandler={() => setShowWhatsappDialog(false)}
                />
            ) : null}
        </>
    );
};
