export const config = {
    "build_timestamp": "2023-12-21T21:26:24.988Z",
    "geoKey": "AIzaSyBeeIWUhRhc2ZW9oKxUugzu8y9JQgFVcvA",
    "zoomSdkKey": "Egz3OjF7ITKvLvhHxRhxLNa5us9uJQ24oJX7",
    "mockServices": false,
    "env": "dh",
    "serverUrl": "https://events.eonlineworkshop.com/dhWebsite",
    "imgUrl": "https://events.eonlineworkshop.com/",
    "razorAppKey": "rzp_live_yxYVhNWAkRzybF"
};
