import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { Button, Card, CardContent, CardMedia } from "@mui/material";

import image_event_dh from "../assets/site/event_dh.png";
import { PayNowButton } from "./PayNowBtn";
import { useNavigate } from "react-router";
import { useState } from "react";
import {
    DialogContent,
    Dialog,
    DialogTitle,
    DialogContentText,
    DialogActions,
} from "@mui/material";
import { getUserKey, postData } from "../common/utils";
import { config } from "../env/config";

export const EventDetails = ({ event, refreshEvents }) => {
    const { isSubscribed, media } = event;
    return (
        <>
            <Card
                sx={{
                    display: { xs: "none", md: "flex" },
                    boxShadow: "none",
                    marginBottom: "15px",
                }}
            >
                <CardMedia
                    component="img"
                    sx={{ width: 151, borderRadius: 2 }}
                    image={media || image_event_dh}
                />
                <Box
                    sx={{
                        marginLeft: 2,
                        background: "#F8F9FF",
                        flex: 1,
                        borderRadius: 2,
                        display: "flex",
                        alignItems: "center",
                    }}
                >
                    <CardContent
                        sx={{
                            display: "flex",
                            justifyContent: "space-between",
                            width: "100%",
                            alignItems: "center",
                        }}
                    >
                        <Box
                            sx={{
                                display: "flex",
                                flexDirection: "column",
                                justifyContent: "center",
                            }}
                        >
                            <EventDescription event={event} />
                        </Box>
                        <EventActionButton
                            event={event}
                            refreshEvents={refreshEvents}
                            isSubscribed={isSubscribed}
                        />
                    </CardContent>
                </Box>
            </Card>

            <Card
                sx={{
                    boxShadow: "none",
                    marginBottom: "15px",
                    display: { xs: "flex", md: "none" },
                }}
            >
                <CardContent
                    sx={{
                        width: "100%",
                        padding: "20px",
                        background: { xs: "#F8F9FF", md: "none" },
                        borderRadius: "4px",
                    }}
                >
                    <Box
                        sx={{
                            display: "flex",
                            columnGap: "8px",
                            marginBottom: "24px",
                        }}
                    >
                        <CardMedia
                            component="img"
                            sx={{ width: 60, height: "60px", borderRadius: 2 }}
                            image={media || image_event_dh}
                        />
                        <Box
                            sx={{
                                display: "flex",
                                flexDirection: "column",
                                justifyContent: "center",
                            }}
                        >
                            <EventDescription event={event} />
                        </Box>
                    </Box>
                    <EventActionButton
                        event={event}
                        refreshEvents={refreshEvents}
                        isSubscribed={isSubscribed}
                    />
                </CardContent>
            </Card>
        </>
    );
};

const EventDescription = ({ event }) => {
    const { eventname, eventdate, event_time } = event;
    return (
        <>
            <Typography
                component="div"
                variant="h5"
                sx={{ fontSize: { xs: "15px", md: "18px" } }}
                fontFamily="Open Sans, Semibold"
            >
                {eventname}
            </Typography>
            <Typography
                variant="subtitle1"
                color="#000"
                sx={{ fontSize: { xs: "13px", md: "px" } }}
                component="div"
            >
                {`${eventdate} ${event_time}`}
            </Typography>
            {event.isCancel === "1" && (
                <Typography
                    variant="subtitle1"
                    color="#c35656"
                    sx={{ fontSize: { xs: "13px", md: "px" } }}
                    component="div"
                >
                    {event.eventCancelReason}
                </Typography>
            )}
        </>
    );
};

const EventActionButton = ({ event, refreshEvents, isSubscribed }) => {
    const navigate = useNavigate();
    const [showEventJoinMessage, setShowEventJoinMessage] = useState(false);

    const handleEventJoin = () => {
        setShowEventJoinMessage(true);
    };

    const cancelEventJoin = () => {
        setShowEventJoinMessage(false);
    };

    const handleMeetingJoin = () => {
        navigate("/meeting", {
            state: {
                meetingId: event.meeting_id,
                meetingPass: event.meeting_pass,
                zoomUserId: event.zoomUserId,
                zoomSignature: event.zoomSignature,
            },
        });
    };
    return (
        <>
            {isSubscribed ? (
                <Button
                    variant="contained"
                    size="small"
                    onClick={handleEventJoin}
                    disabled={event.btnStatus === "0" ? true : false}
                    sx={{
                        display: `${event.isCancel === "1" ? "none" : "flex"}`,
                        width: { xs: "100%", md: "220px" },
                        height: "40px",
                        background: "#54B14C",
                    }}
                >
                    JOIN EVENT
                </Button>
            ) : (
                <PayNowButton event={event} refreshEvents={refreshEvents} />
            )}
            <EventJoinDialog
                show={showEventJoinMessage}
                msg={event.eventMsg}
                eventId={event.id}
                handleJoin={handleMeetingJoin}
                cancelEventJoin={cancelEventJoin}
            />
        </>
    );
};

function EventJoinDialog({ show, msg, eventId, handleJoin, cancelEventJoin }) {
    const userData = getUserKey();

    const postEventJoin = async () => {
        const payload = {
            user_id: userData.id,
            event_id: eventId,
        };
        const joinDetails = await postData({
            url: `${config.serverUrl}/eventjoin.php`,
            payload,
        });

        if (joinDetails) {
            handleJoin();
        }
    };

    return (
        <Dialog open={show} onClose={cancelEventJoin}>
            <DialogTitle>Event Details</DialogTitle>
            <DialogContent>
                <DialogContentText
                    sx={{
                        fontSize: { xs: "15px", md: "18px" },
                        whiteSpace: "pre-wrap",
                    }}
                    fontFamily="Open Sans, Semibold"
                >
                    {msg}
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={cancelEventJoin}>Cancel</Button>
                <Button onClick={postEventJoin}>Join</Button>
            </DialogActions>
        </Dialog>
    );
}
