import * as React from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import Stack from "@mui/material/Stack";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import PersonOutlinedIcon from "@mui/icons-material/PersonOutlined";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";

import image_logo from "../assets/site/logo.png";
import dadashree_img from "../assets/site/dadashree2.png";
import { getUserKey, postData, setUserKey } from "../common/utils";
import {
    Checkbox,
    FormControlLabel,
    InputAdornment,
    InputLabel,
} from "@mui/material";
import { useState } from "react";
import ForgotPassword from "./ForgotPassword";
import { Loader } from "../common/loader/Loader";
import { displayRazorpayPaymentSdk } from "../payment/payment";
import { config } from "../env/config";
import RegistrationPaymentDialog from "./RegistrationPaymentDialog";

const mediumTextStyle = {
    fontFamily: "Open Sans, Regular",
    color: "#4D285C",
    fontSize: "14px",
};

const validationSchema = yup.object({
    email: yup.string().required("Required"),
    password: yup.string().required("Required"),
});

export default function Login() {
    const navigate = useNavigate();
    const userData = getUserKey();
    const [showForgotPasswordPopup, setShowForgotPasswordPopup] = useState();
    const [registrationPaymentStatus, setRegistrationPaymentStatus] =
        useState("");
    const [showLoader, setShowLoader] = React.useState(false);

    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm({
        resolver: yupResolver(validationSchema),
    });

    const onSubmit = async (values) => {
        if (values.rememberMe) {
        }

        try {
            const payload = {
                username: values.email,
                password: values.password,
            };
            const res = await postData({
                url: `${config.serverUrl}/login.php`,
                payload,
                highlightError: true,
            });
            if (res) {
                setUserKey(res);
                if (res.regStatus === 0) {
                    setRegistrationPaymentStatus(res);
                } else {
                    navigate("/dashboard");
                    toast.success("Logged in successfully!");
                }
            }
        } catch (error) {
            toast.error("Something went wrong!");
            console.log("Something went wrong");
        }
    };

    const submitRegistrationPayment = () => {
        const loginRes = getUserKey();
        makeRegistrationPayment(loginRes, () => {
            setRegistrationPaymentStatus("");
            navigate("/dashboard");
            toast.success("Logged in successfully!");
        });
    };

    const makeRegistrationPayment = async (res, onSuccess) => {
        const payload = {
            mobile: res.mobile,
            memberId: res.member_id,
            name: res.name,
            amount: res.regAmount * 100,
            currency: res.currency,
            email: res.email,
            description: "Registration",
            apkRefNo: `${res.member_id}${Number(new Date())}`,
            bookInfo: "Sign up Payment",
        };

        try {
            const res = await postData({
                url: `${config.serverUrl}/getRazorpayOrderId.php`,
                payload,
            });
            if (res) {
                displayRazorpayPaymentSdk({
                    ...res,
                    setShowLoader,
                    onPaymentSuccess: sendPaymentUpdate,
                    successCb: onSuccess,
                });
            }
        } catch (error) {
            toast.error("Something went wrong!");
            console.log("Something went wrong");
        }
    };

    const sendPaymentUpdate = async (response) => {
        const payload = {
            booked_by: userData.name,
            country_id: userData.country_id,
            member_id: userData.member_id,
            online_payment_id: response.onlinePaymentId,
            order_id: response.razorpay_order_id,
            payment_status: "success",
            signature: response.razorpay_signature,
            state_id: userData.state_id,
            transaction_id: response.razorpay_payment_id,
            user_id: userData.id,
        };

        const res = await postData({
            url: `${config.serverUrl}/singupPaymentCheckout.php`,
            payload,
        });
        setShowLoader(false);
        if (res) {
            response.successCb && response.successCb();
            toast.success("Registration Update Successful!");
        }
    };

    const hasError = (element) => !!errors[element];
    const getError = (element) => {
        if (errors[element]) {
            return Object.values(errors[element])[0];
        }
    };

    const onSignup = () => {
        navigate("/registration");
    };

    const handleForgotPassword = () => {
        setShowForgotPasswordPopup(true);
    };

    return (
        <Container
            maxWidth={false}
            disableGutters
            component="main"
            sx={{
                height: "100%",
            }}
        >
            <Stack
                direction={{ xs: "column", md: "row" }}
                spacing={0}
                height="100%"
                width="100%"
            >
                <Box
                    flex={2}
                    sx={{
                        background: "#49255d",
                        alignItems: "center",
                        justifyContent: "center",
                        display: "flex",
                        position: "relative",
                        padding: { xs: "16px", md: 0 },
                    }}
                >
                    <Stack spacing={3} alignItems="center">
                        <Box
                            component="img"
                            sx={{
                                width: { xs: 75, md: 135 },
                            }}
                            alt="Divine Harmony"
                            src={image_logo}
                        />
                        <Box textAlign="center">
                            <Typography
                                color="white"
                                component="h1"
                                variant="h3"
                                fontSize="24px"
                                fontFamily="Belleza, sans-serif"
                            >
                                Welcome to
                            </Typography>
                            <Typography
                                color="white"
                                component="h1"
                                variant="h3"
                                fontSize={{ xs: 24, md: 39 }}
                                fontFamily="Belleza, sans-serif"
                            >
                                Event Booking
                            </Typography>
                        </Box>
                        <Box
                            component="img"
                            sx={{
                                width: { xs: 250, md: 350 },
                            }}
                            alt="Shreekalp"
                            src={dadashree_img}
                        />
                    </Stack>
                </Box>
                <Box
                    flex={3}
                    display="flex"
                    alignItems="center"
                    flexDirection="column"
                    justifyContent="center"
                    sx={{
                        padding: { xs: "16px", md: 0 },
                    }}
                >
                    <Typography
                        color="#4B285D"
                        fontFamily="Open Sans, Semibold"
                        component="h1"
                        sx={{ mt: 2 }}
                        variant="h5"
                    >
                        Sign In
                    </Typography>
                    <Box
                        component="form"
                        noValidate
                        onSubmit={handleSubmit(onSubmit)}
                        sx={{ mt: 3, width: { xs: "100%", md: 400 } }}
                    >
                        <Grid
                            container
                            spacing={2}
                            sx={{
                                padding: { xs: "16px", md: 0 },
                            }}
                        >
                            <Grid item xs={12}>
                                <InputLabel shrink htmlFor="email">
                                    Username
                                </InputLabel>
                                <TextField
                                    fullWidth
                                    size="small"
                                    id="email"
                                    name="email"
                                    autoComplete="email"
                                    {...register("email")}
                                    error={hasError("email")}
                                    helperText={getError("email")}
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <PersonOutlinedIcon />
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <InputLabel shrink htmlFor="email">
                                    Password
                                </InputLabel>
                                <TextField
                                    fullWidth
                                    size="small"
                                    name="password"
                                    type="password"
                                    id="password"
                                    autoComplete="new-password"
                                    {...register("password")}
                                    error={hasError("password")}
                                    helperText={getError("password")}
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <LockOutlinedIcon />
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <Button
                                    type="submit"
                                    fullWidth
                                    style={{
                                        backgroundColor: "#F67800",
                                    }}
                                    variant="contained"
                                    sx={{ mt: 2 }}
                                >
                                    Login
                                    <Loader show={showLoader} />
                                </Button>
                            </Grid>
                            <Grid item xs={12}>
                                <FormControlLabel
                                    control={<Checkbox />}
                                    name="rememberMe"
                                    type="rememberMe"
                                    id="rememberMe"
                                    {...register("rememberMe")}
                                    error={hasError("rememberMe")}
                                    label={
                                        <Typography style={mediumTextStyle}>
                                            Remember Me
                                        </Typography>
                                    }
                                />
                            </Grid>
                            <Grid
                                display="flex"
                                flexDirection="column"
                                alignItems="center"
                                item
                                xs={12}
                            >
                                <Button
                                    style={mediumTextStyle}
                                    onClick={handleForgotPassword}
                                >
                                    Forgot Password?
                                </Button>
                                <Typography style={mediumTextStyle}>
                                    Helpline No: +919604489537
                                </Typography>
                            </Grid>
                            <Grid
                                display="flex"
                                justifyContent="center"
                                item
                                xs={12}
                            >
                                <Button
                                    type="submit"
                                    style={{
                                        backgroundColor: "#64B443",
                                        width: 185,
                                    }}
                                    variant="contained"
                                    sx={{ mt: 2, mb: 2 }}
                                    onClick={onSignup}
                                >
                                    New User Signup
                                </Button>
                            </Grid>
                        </Grid>
                    </Box>
                </Box>
            </Stack>
            <ForgotPassword
                showForgotPasswordPopup={showForgotPasswordPopup}
                setShowForgotPasswordPopup={setShowForgotPasswordPopup}
            />
            <RegistrationPaymentDialog
                onSubmit={submitRegistrationPayment}
                state={registrationPaymentStatus}
                onClose={() => setRegistrationPaymentStatus("")}
            />
        </Container>
    );
}
