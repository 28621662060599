import React from "react";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Carousel from "react-material-ui-carousel";
import {
    Button,
    Card,
    CardContent,
    Grid,
    TextField,
    Typography,
} from "@mui/material";

import balanced_mind_home from "../assets/site/landing/balanced_mind_home.webp";
import chakra_balancing from "../assets/site/landing/chakra_balancing.webp";
import permanent_wellbeing from "../assets/site/landing/permanent_wellbeing.webp";
import remove_negativity from "../assets/site/landing/remove_negativity.webp";
import stress_release from "../assets/site/landing/stress_release.webp";
import design_heading from "../assets/site/landing/design_heading.webp";
import love_icon from "../assets/site/landing/love-icon.webp";
import happiness_icon from "../assets/site/landing/happiness_icon.webp";
import knowledge_icon from "../assets/site/landing/knowledge-icon.webp";
import about_dh_background from "../assets/site/landing/about_dh_background.webp";
import dadashree_half from "../assets/site/landing/Dadashree_Half.webp";
import upcoming_event_bg from "../assets/site/landing/upcoming_event_bg.webp";
import text_design_2 from "../assets/site/landing/text_design_2.webp";
import thoughts1 from "../assets/site/landing/thoughts/thoughts1.jpg";
import thoughts2 from "../assets/site/landing/thoughts/thoughts2.jpg";
import thoughts3 from "../assets/site/landing/thoughts/thoughts3.jpg";
import thoughts4 from "../assets/site/landing/thoughts/thoughts4.jpg";
import thoughts5 from "../assets/site/landing/thoughts/thoughts5.jpg";
import thoughts6 from "../assets/site/landing/thoughts/thoughts6.jpg";
import thoughts7 from "../assets/site/landing/thoughts/thoughts7.jpg";
import thoughts8 from "../assets/site/landing/thoughts/thoughts8.jpg";
import thoughts9 from "../assets/site/landing/thoughts/thoughts9.jpg";
import thoughts10 from "../assets/site/landing/thoughts/thoughts10.jpg";
import thoughts11 from "../assets/site/landing/thoughts/thoughts11.jpg";
import thoughts12 from "../assets/site/landing/thoughts/thoughts12.jpg";
import thoughts13 from "../assets/site/landing/thoughts/thoughts13.jpeg";
import thoughts14 from "../assets/site/landing/thoughts/thoughts14.jpg";
import thoughts15 from "../assets/site/landing/thoughts/thoughts15.jpg";
import thoughts16 from "../assets/site/landing/thoughts/thoughts16.jpg";
import thoughts17 from "../assets/site/landing/thoughts/thoughts17.jpg";
import thoughts18 from "../assets/site/landing/thoughts/thoughts18.jpg";
import thoughts19 from "../assets/site/landing/thoughts/thoughts19.jpg";
import thoughts20 from "../assets/site/landing/thoughts/thoughts20.jpg";
import aspirations_video from "../assets/site/landing/aspirations_video.mp4";
import { css } from "@emotion/css";
import { useNavigate } from "react-router";
import { ContactUs } from "./components/ContactUs";
import { Header } from "./components/Header";
import { BackgroundImg } from "./components/BackgroundImg";

import {
    ContentText,
    MediaBackgroundImage,
    MediaBackgroundVideo,
    MediaImage,
    SecondaryHeading,
    SectionHeading,
} from "../common/typography";
import { commonCarouselNavStyle } from "../common/utils";

const carosuselImgList = [
    {
        imgUrl: balanced_mind_home,
        imgText: "Balanced and Peaceful Mind",
        id: 1,
        textStyle: {
            position: "absolute",
            top: { xs: "20px", md: "100px" },
            left: { xs: "20px", md: "200px" },
            width: { xs: "70px", md: "200px" },
            textAlign: "center",
            fontSize: { xs: "32px", md: "40px" },
            color: "#6E3490",
        },
    },
    {
        imgUrl: chakra_balancing,
        imgText: "Chakra Balancing",
        id: 2,
        textStyle: {
            position: "absolute",
            top: { xs: "2px", md: "50px" },
            left: { xs: "5px", md: "100px" },
            color: "#6E3490",
            fontSize: { xs: "32px", md: "40px" },
        },
    },
    {
        imgUrl: permanent_wellbeing,
        imgText: "Permanent Wellbeing",
        id: 3,
        textStyle: {
            position: "absolute",
            top: { xs: "20px", md: "50px" },
            right: { xs: "70px", md: "150px" },
            width: { xs: "80px", md: "auto" },
            color: "#6E3490",
            fontSize: { xs: "32px", md: "40px" },
        },
    },
    {
        imgUrl: remove_negativity,
        imgText: "Remove Negativity",
        id: 4,
        textStyle: {
            position: "absolute",
            top: { xs: "20px", md: "70px" },
            left: { xs: "20px", md: "100px" },
            color: "#fff",
            fontSize: { xs: "32px", md: "40px" },
        },
    },
    {
        imgUrl: stress_release,
        imgText: "Stress Release",
        id: 5,
        textStyle: {
            position: "absolute",
            top: { xs: "10px", md: "50px" },
            left: { xs: "20px", md: "275px" },
            color: "#6E3490",
            fontSize: { xs: "32px", md: "40px" },
        },
    },
];

const thoughtsList = [
    thoughts1,
    thoughts2,
    thoughts3,
    thoughts4,
    thoughts5,
    thoughts6,
    thoughts7,
    thoughts8,
    thoughts9,
    thoughts10,
    thoughts11,
    thoughts12,
    thoughts13,
    thoughts14,
    thoughts15,
    thoughts16,
    thoughts17,
    thoughts18,
    thoughts19,
    thoughts20,
];

const Vision = () => (
    <Grid item xs={12}>
        <Container maxWidth="md" sx={{ mt: 3, mb: 4, pb: 4 }}>
            <Card sx={{ minWidth: 275 }}>
                <CardContent style={{ textAlign: "center" }}>
                    <SectionHeading sx={{ marginBottom: 0 }} color="#D69129">
                        Our Vision
                    </SectionHeading>
                    <MediaImage
                        src={design_heading}
                        sx={{
                            width: { xs: 230, md: 336 },
                            height: { xs: 26, md: 45 },
                        }}
                    />
                    <Typography
                        variant="h5"
                        component="p"
                        fontSize="30px"
                        fontWeight="500"
                        fontFamily="barlow"
                    >
                        Unconditional
                    </Typography>
                    <Box
                        sx={{
                            display: "flex",
                            columnGap: "20px",
                            justifyContent: "center",
                            mb: 2,
                            mt: 2,
                        }}
                    >
                        <div>
                            <ContentText bold color="#10A9EB">
                                Love
                            </ContentText>
                            <MediaImage
                                src={love_icon}
                                sx={{
                                    width: { xs: 80, md: 114 },
                                    height: { xs: 70, md: 98 },
                                }}
                            />
                        </div>
                        <div>
                            <ContentText bold color="#ED5829">
                                Knowledge
                            </ContentText>
                            <MediaImage
                                src={knowledge_icon}
                                sx={{
                                    width: { xs: 70, md: 100 },
                                    height: { xs: 70, md: 98 },
                                }}
                            />
                        </div>
                        <div>
                            <ContentText bold color="#C7BE0C">
                                Happiness
                            </ContentText>
                            <MediaImage
                                src={happiness_icon}
                                sx={{
                                    width: { xs: 80, md: 118 },
                                    height: { xs: 66, md: 98 },
                                }}
                            />
                        </div>
                    </Box>
                    <Box
                        sx={{
                            width: {
                                xs: "auto",
                                lg: "700px",
                            },
                            margin: "auto",
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "center",
                            rowGap: "20px",
                        }}
                    >
                        <ContentText>
                            Our vision is to create a healthy, peaceful and
                            stress-free society. We have all the luxuries
                            necessary for our wellbeing but still we feel
                            emptiness from inside because we all are in search
                            of more <b>Love</b>, more <b>Knowledge</b> and more
                            <b> Happiness</b>.
                        </ContentText>
                        <ContentText>
                            Divine harmony therapy transform our energy so that
                            our love become unconditional which leads us towards
                            ultimate knowledge followed by permanent happiness.
                        </ContentText>
                    </Box>
                </CardContent>
            </Card>
        </Container>
    </Grid>
);

const AboutDivineHarmony = () => (
    <Grid item xs={12}>
        <SectionHeading sx={{ marginBottom: 0 }} color="#8B0000">
            About Divine Harmony
        </SectionHeading>
        <div style={{ textAlign: "center" }}>
            <MediaImage
                src={design_heading}
                sx={{
                    width: { xs: 220, md: 220 },
                    height: { xs: 26, md: 34 },
                    marginBottom: "-7px",
                }}
            />
        </div>
        <Box
            sx={{
                backgroundImage: `url(${about_dh_background})`,
                backgroundSize: "cover",
            }}
        >
            <Container maxWidth="md" sx={{ pt: 5, pb: 5 }}>
                <Box
                    sx={{
                        width: { xs: "auto", lg: "418px", textAlign: "center" },
                        display: "flex",
                        flexDirection: "column",
                        rowGap: "20px",
                    }}
                >
                    <SecondaryHeading
                        sx={{ fontSize: { xs: "18px", md: "20px" } }}
                        color="#CB3DD9"
                    >
                        Divine Harmony is a journey of rediscovering human
                        intelligence.
                    </SecondaryHeading>
                    <ContentText>
                        Divine Harmony is an energy-based therapy. This is very
                        effective in healing our energies which is exhausted due
                        to our busy lifestyle, stress, over thinking habits etc.
                    </ContentText>
                    <ContentText>
                        This is a very unique concept of human energy
                        transformation to rendezvous us with our real self and
                        connect us with our inner peace.
                    </ContentText>
                </Box>
            </Container>
        </Box>
    </Grid>
);

const AboutShreekalp = () => (
    <Grid item xs={12}>
        <Container maxWidth="md" sx={{ mt: 3, mb: 4, pb: 4 }}>
            <Card sx={{ minWidth: 275 }}>
                <CardContent style={{ textAlign: "center" }}>
                    <MediaImage
                        src={design_heading}
                        sx={{
                            width: { xs: 230, md: 275 },
                            height: { xs: 26, md: 35 },
                        }}
                    />
                    <SectionHeading color="#D69129">
                        About Shreekalp
                    </SectionHeading>
                    <Box
                        sx={{
                            display: "flex",
                            flexDirection: { xs: "column", md: "row" },
                            alignItems: "center",
                            marginTop: "30px",
                        }}
                    >
                        <MediaImage
                            src={dadashree_half}
                            sx={{
                                width: { xs: 218, md: 368 },
                                height: { xs: 188, md: 322 },
                            }}
                        />
                        <Box
                            sx={{
                                width: { xs: "auto", sm: 400 },
                                display: "flex",
                                flexDirection: "column",
                                rowGap: "20px",
                            }}
                        >
                            <ContentText bold color="#CB3DD9">
                                Your Companion, Mentor & Guide for Life​​
                            </ContentText>
                            <ContentText>
                                Shreekalp is a contemporary spiritual mentor and
                                a profound philosopher whose style of
                                spirituality is quite unique. Due to his
                                simplicity you can connect with him easily and
                                accept him as a companion guide. As a result,
                                you will receive positive energy from him which
                                will help you for your permanent wellbeing.
                            </ContentText>
                            <ContentText>
                                Shreekalp has invented Divine Harmony therapy,
                                through which he guides us and enhance our
                                energy for our physical, mental, emotional and
                                spiritual growth for ultimate self liberation.
                            </ContentText>
                        </Box>
                    </Box>
                </CardContent>
            </Card>
        </Container>
    </Grid>
);

const Aspirations = () => (
    <Grid
        item
        xs={12}
        style={{
            position: "relative",
            minHeight: "420px",
        }}
    >
        <MediaBackgroundVideo
            backdropColor="#FFFFFF00"
            opacity="1"
            src={aspirations_video}
        />
        <Box>
            <Container maxWidth="md" sx={{ mt: 3, mb: 4, pb: 4 }}>
                <SectionHeading color="#001D4B" textAlign="left">
                    Meet your aspirations with shreekalp
                </SectionHeading>
                <Box
                    sx={{
                        width: { xs: "auto", sm: 400 },
                        display: "flex",
                        flexDirection: "column",
                        rowGap: "20px",
                        textAlign: "center",
                        mt: 3,
                    }}
                >
                    <Typography
                        variant="body2"
                        fontFamily="josefin slab,serif"
                        fontSize="24px"
                        fontWeight="700"
                        color="#566FB8"
                    >
                        Know Your Life
                    </Typography>
                    <ContentText
                        bold
                        sx={{
                            background: { xs: "#ffffff78", md: "none" },
                            borderRadius: "4px",
                        }}
                    >
                        With peaceful and tranquil mind you can get clarity
                        about your life and your goals. Calm and stable mind
                        opens tremendous opportunities in your life and
                        immensely increase your potential.
                    </ContentText>
                    <ContentText
                        bold
                        sx={{
                            background: { xs: "#ffffff78", md: "none" },
                            borderRadius: "4px",
                        }}
                    >
                        With Shreekalp's accurate energy reading guidance you
                        can put your efforts in the right direction as a result
                        you will meet your aspirations and overcome the
                        obstacles of your success.
                    </ContentText>
                </Box>
            </Container>
        </Box>
    </Grid>
);

const UpcomingEvent = () => (
    <Grid item xs={12}>
        <SectionHeading color="#8B0000" sx={{ marginBottom: 0 }}>
            upcoming event
        </SectionHeading>
        <div style={{ textAlign: "center" }}>
            <MediaImage
                src={text_design_2}
                sx={{
                    width: { xs: 230, md: 335 },
                    height: { xs: 26, md: 28 },
                }}
            />
        </div>
        <div style={{ position: "relative" }}>
            <MediaBackgroundImage
                opacity="1"
                backdropColor="#FFFFFF00"
                src={upcoming_event_bg}
            />
            <Container maxWidth="md" sx={{ pt: 5, pb: 5 }}>
                <Box
                    sx={{
                        textAlign: "center",
                        display: "flex",
                        flexDirection: "column",
                        rowGap: "20px",
                    }}
                >
                    <Typography
                        variant="body2"
                        fontFamily="sans-serif"
                        color="#B713BD"
                        sx={{
                            fontSize: { xs: "29px", md: "40px" },
                        }}
                    >
                        Join us @
                    </Typography>
                    <Typography
                        variant="body2"
                        fontFamily="oswald"
                        sx={{
                            fontSize: { xs: "24px", md: "40px" },
                        }}
                    >
                        Online Divine Harmony Session
                    </Typography>
                    <ContentText sx={{ fontSize: { xs: "18px", md: "20px" } }}>
                        Give yourself a treat of stress release without the
                        struggle of lengthy meditation or yoga practices.
                    </ContentText>
                    <ContentText sx={{ fontSize: { xs: "18px", md: "20px" } }}>
                        Registrations are open.
                        <br />
                        Book your seat now to confirm your entry in the next
                        session.
                    </ContentText>
                    <Typography
                        variant="body2"
                        fontFamily="cinzel"
                        fontWeight="700"
                        color="#FF4040"
                        sx={{
                            fontSize: { xs: "19px", md: "24px" },
                        }}
                    >
                        Limited Seats Available
                    </Typography>
                </Box>
            </Container>
        </div>
    </Grid>
);

const Thoughts = () => {
    let list = [];
    let subList = [];
    thoughtsList.forEach((item, index) => {
        subList.push(item);
        if (index % 2 === 1) {
            list.push(subList);
            subList = [];
        }
    });

    return (
        <Grid item xs={12}>
            <SectionHeading color="#8B0000" sx={{ marginBottom: 0 }}>
                SHREEKALP'S THOUGHTS
            </SectionHeading>
            <div style={{ textAlign: "center" }}>
                <MediaImage
                    src={text_design_2}
                    sx={{
                        width: { xs: 242, md: 335 },
                        height: { xs: 22, md: 28 },
                    }}
                />
            </div>
            <Container
                sx={{ pb: 5, maxWidth: { xs: "100%", md: "md", lg: "lg" } }}
            >
                <Carousel
                    navButtonsAlwaysVisible
                    navButtonsProps={{
                        className: commonCarouselNavStyle,
                    }}
                >
                    {list.map((thought, index) => (
                        <div
                            key={`thoughts-${index}`}
                            className={css({
                                display: "flex",
                                columnGap: "15px",
                                justifyContent: "center",
                                contain: { xs: "size", md: "" },
                                minHeight: "227px",
                            })}
                        >
                            <MediaImage
                                src={thought[0]}
                                sx={{
                                    maxWidth: "100%",
                                    minHeight: { xs: 227, lg: 402 },
                                    height: { xs: 227, lg: 402 },
                                    width: { xs: 227, lg: 402 },
                                    borderRadius: "4px",
                                    border: "1px solid #E8E9E1",
                                    contain: { xs: "size", md: "" },
                                }}
                            />
                            <MediaImage
                                src={thought[1]}
                                sx={{
                                    maxWidth: "100%",
                                    minHeight: { xs: 227, lg: 402 },
                                    height: { xs: 227, lg: 402 },
                                    width: { xs: 227, lg: 402 },
                                    borderRadius: "4px",
                                    border: "1px solid #E8E9E1",
                                    contain: { xs: "size", md: "" },
                                }}
                            />
                        </div>
                    ))}
                </Carousel>
            </Container>
        </Grid>
    );
};

const MailingList = () => (
    <Grid item xs={12}>
        <Container maxWidth="md" sx={{ pb: 5, textAlign: "center" }}>
            <Box>
                <SectionHeading color="#A620B3">
                    JOIN THE MAILING LIST
                </SectionHeading>
                <Box
                    sx={{
                        background: "#F9C5B466",
                        border: "1px solid #f9c5b5",
                        borderRadius: "4px",
                        padding: "20px",
                    }}
                >
                    <Typography
                        sx={{
                            fontSize: "20px",
                            lineHeight: "30px",
                            fontFamily: "sans-serif",
                            marginBottom: "20px",
                        }}
                        gutterBottom
                    >
                        Knowledge can bring happiness in your life. For more
                        life changing thoughts and updates about future events
                        please subscribe with us by joining our mailing list.
                    </Typography>
                    <Box
                        sx={{
                            display: "flex",
                            flexDirection: "column",
                            rowGap: "10px",
                            alignItems: "center",
                            padding: "0 70px",
                        }}
                    >
                        <TextField
                            name="name"
                            fullWidth
                            id="name"
                            label="Name"
                            size="small"
                        />
                        <TextField
                            autoComplete="email"
                            name="email"
                            fullWidth
                            size="small"
                            id="email"
                            label="Enter your Email"
                        />
                        <Button
                            type="button"
                            onClick={() => {}}
                            variant="contained"
                            size="small"
                            sx={{
                                mt: { xs: 2, sm: 0 },
                                background: "#a721b3",
                                width: { xs: "100%", sm: "220px" },
                                height: "50px",
                            }}
                        >
                            Subscribe
                        </Button>
                    </Box>
                </Box>
            </Box>
        </Container>
    </Grid>
);

const LandingPage = () => {
    const navigate = useNavigate();
    return (
        <div>
            <Box>
                <Header />
                <div>
                    <Box sx={{ position: "relative" }}>
                        <Carousel
                            navButtonsAlwaysVisible
                            navButtonsProps={{
                                className: commonCarouselNavStyle,
                            }}
                        >
                            {carosuselImgList.map((img, index) => (
                                <Box
                                    key={`image-${index}`}
                                    style={{ position: "relative" }}
                                >
                                    <Box
                                        key={img.id}
                                        component="img"
                                        sx={{
                                            width: "100%",
                                            height: {
                                                xs: "280px",
                                                md: "500px",
                                            },
                                        }}
                                        alt="Landing Photo"
                                        src={img.imgUrl}
                                    />
                                    <Box>
                                        <Typography
                                            sx={{
                                                ...img.textStyle,
                                                color: "#ffd4be",
                                            }}
                                            component="h3"
                                            variant="h3"
                                            mb={1}
                                        >
                                            {img.imgText}
                                        </Typography>
                                        <Typography
                                            sx={img.textStyle}
                                            className={img.animationClass}
                                            component="h3"
                                            variant="h3"
                                            mb={1}
                                        >
                                            {img.imgText}
                                        </Typography>
                                    </Box>
                                </Box>
                            ))}
                        </Carousel>
                    </Box>

                    <Grid container spacing={3}>
                        <Vision />
                        <AboutDivineHarmony />
                        <AboutShreekalp />
                        <Aspirations />
                        <UpcomingEvent />
                        <Thoughts />
                        {/* <MailingList /> */}
                        <ContactUs />
                    </Grid>
                </div>
            </Box>

            <BackgroundImg />
        </div>
    );
};

export default LandingPage;
