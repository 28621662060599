import { css } from "@emotion/css";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Typography,
} from "@mui/material";
import React from "react";

const detailsRowStyle = css({
  display: "flex",
  justifyContent: "space-between",
  marginTop: 10,
});
const legalLinksStyle = css({
  display: "flex",
  justifyContent: "flex-end",
  marginRight: "-10px",
  marginBottom: "-10px",
});
const RegistrationPaymentDialog = ({ state, onClose, onSubmit }) => {
  console.log(state);
  return (
    <>
      {state && (
        <Dialog fullWidth maxWidth="sm" open onClose={onClose}>
          <DialogTitle>User Registration</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Please complete the registration process by paying registration
              charges.
            </DialogContentText>
            <div className={detailsRowStyle}>
              <Typography component="p" variant="p">
                Name
              </Typography>
              <Typography component="p" variant="p">
                {state.name}
              </Typography>
            </div>
            <div className={detailsRowStyle}>
              <Typography component="p" variant="p">
                Mobile
              </Typography>
              <Typography align="left" component="p" variant="p">
                {state.mobile}
              </Typography>
            </div>
            <div className={detailsRowStyle}>
              <Typography component="p" variant="p">
                Email
              </Typography>
              <Typography component="p" variant="p">
                {state.email}
              </Typography>
            </div>
            <div className={legalLinksStyle}>
              <Button
                target="_blank"
                sx={{
                  textTransform: "none",
                  fontSize: "14px",
                  color: "#0062FF",
                  marginLeft: "0px",
                  width: "fit-content",
                }}
                href="https://global.eonlineworkshop.com/g_privacypolicy_refund.html"
              >
                Privacy Policy
              </Button>
              <Button
                target="_blank"
                sx={{
                  textTransform: "none",
                  fontSize: "14px",
                  color: "#0062FF",
                  marginLeft: "0px",
                  width: "fit-content",
                }}
                href="https://global.eonlineworkshop.com/g_termsnconditions.html"
              >
                Terms & Conditions
              </Button>
            </div>
          </DialogContent>
          <DialogActions>
            <Button variant="outlined" onClick={onClose}>
              Cancel
            </Button>
            <Button variant="outlined" color="success" onClick={onSubmit}>
              Make Payment
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </>
  );
};

export default RegistrationPaymentDialog;
