import { config } from "../env/config";

const domesticCountryList = [
    { country_id: "101", country_code: "IN", name: "India" },
];

const globalCountryList = [
    { country_id: "13", country_code: "AU", name: "Australia" },
    { country_id: "38", country_code: "CA", name: "Canada" },
    { country_id: "196", country_code: "SG", name: "Singapore" },
    { country_id: "230", country_code: "UK", name: "United Kingdom" },
    { country_id: "231", country_code: "US", name: "United States" },
    { country_id: "82", country_code: "DE", name: "Germany" },
    { country_id: "205", country_code: "ES", name: "Spain" },
    { country_id: "157", country_code: "NZ", name: "New Zealand" },
];

export const getCountryList = () =>
    config.env === "global" ? globalCountryList : domesticCountryList;

export const defaultThought = {
    homeQuotes: [],
    homeRelaxPlayList: [],
    homeBanner: [],
    homeYoutube: [],
};
