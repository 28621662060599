import React from "react";
import ReactDOM from "react-dom/client";
import { config } from "../src/env/config";

import "@fontsource/belleza";
import "@fontsource/montserrat";
import "@fontsource/cinzel";
import "@fontsource/caudex";
import "@fontsource/barlow";
import "@fontsource/roboto";
import "helvatica-neue-lt";
import "@fontsource/libre-baskerville";
import "@fontsource/josefin-slab";
import "@fontsource/oswald";
import "./assets/fonts/Futura Lt Light.ttf";
import "./index.css";

import App from "./App";
import reportWebVitals from "./reportWebVitals";

if (process.env.NODE_ENV === "development" && config.mockServices) {
    const { worker } = require("./mocks/browser");
    worker.start();
}

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
    <React.StrictMode>
        <App />
    </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
