import React from "react";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import { Grid, Typography } from "@mui/material";

import what_you_get_bg from "../../assets/site/landing/what_you_get_bg.mp4";
import text_design_2 from "../../assets/site/landing/text_design_2.webp";
import dadashree_half from "../../assets/site/landing/Dadashree_Half.webp";
import design_heading from "../../assets/site/landing/design_heading.webp";
import shreekalp_heading_bg from "../../assets/site/landing/shreekalp_heading_bg.mp4";
import dada_4_img from "../../assets/site/landing/dada_4.webp";
import { ContactUs } from "../components/ContactUs";
import { Header } from "../components/Header";
import { BackgroundImg } from "../components/BackgroundImg";
import {
    ContentList,
    ContentText,
    MediaBackgroundVideo,
    MediaImage,
    SecondaryHeading,
    SectionHeading,
} from "../../common/typography";
import { css, keyframes } from "@emotion/css";

const slideIn = keyframes({
    "0%": { transform: "scaleX(0.25)" },
    "50%": { transform: "scaleX(0.75)" },
    "100%": { transform: "scaleX(1)" },
});

const nameAnimationStyle = css({
    animationName: slideIn,
    animationDuration: "0.6s",
    animationTimingFunction: "linear",
    animationIterationCount: "1",
    animationDirection: "alternate",
    animationFillMode: "forwards",
});

const WhatYouGet = () => (
    <Grid item xs={12} mb={2} style={{ position: "relative" }}>
        <MediaBackgroundVideo opacity={1} src={what_you_get_bg} />

        <Container maxWidth="md" sx={{ pb: 5, pt: 5 }}>
            <SectionHeading color="#8B0000">
                What will you get from Shreekalp
            </SectionHeading>
            <ContentList
                color="#000"
                textItems={[
                    " Stress Release",
                    "Increase Energy Efficiency",
                    "Permanent Wellbeing",
                    "Awaken inner potential",
                    "Discover the inner peace",
                    "Permanent Happiness",
                    "Know your Life",
                ]}
            />
        </Container>
    </Grid>
);

const HeadingSection = () => (
    <Grid item xs={12} mb={3}>
        <Box
            sx={{
                width: "100%",
                height: { xs: 312, md: 406 },
                position: "relative",
            }}
        >
            <MediaBackgroundVideo
                backdropColor="#c5c3e6"
                backgroundGradient="linear-gradient(to top, #ebc0fd 0%, #d9ded8 100%)"
                opacity={1}
                src={shreekalp_heading_bg}
            />
            <Box
                sx={{
                    display: "flex",
                    columnGap: "50px",
                    alignItems: "center",
                    justifyContent: "center",
                    flexDirection: { xs: "column", md: "row" },
                }}
            >
                <MediaImage
                    src={dadashree_half}
                    sx={{
                        width: { xs: 238, md: 445 },
                        height: { xs: 210, md: 402 },
                    }}
                />

                <Box
                    className={nameAnimationStyle}
                    sx={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        justifyContent: "center",
                    }}
                >
                    <Typography
                        sx={{
                            fontFamily: "Caudex",
                            textShadow: "#fff 2px 2px 2px, #777 2px 2px 2px",
                            fontSize: {
                                xs: "37px",
                                md: "60px",
                            },
                            fontWeight: "900",
                            color: "#D71BDE",
                        }}
                    >
                        Shreekalp
                    </Typography>
                    <MediaImage
                        src={text_design_2}
                        sx={{
                            width: { xs: 230, md: 275 },
                            height: { xs: 26, md: 35 },
                        }}
                    />
                    <Typography
                        sx={{
                            fontFamily: "Josefin Slab",
                            fontWeight: "600",
                            fontSize: {
                                xs: "18px",
                                md: "30px",
                            },
                            color: "#A620B3",
                        }}
                    >
                        The Life Designer
                    </Typography>
                </Box>
            </Box>
        </Box>
    </Grid>
);

const AboutShreekalp = () => (
    <Grid item xs={12}>
        <div style={{ textAlign: "center" }}>
            <MediaImage
                src={design_heading}
                sx={{
                    width: { xs: 230, md: 338 },
                    height: { xs: 26, md: 46 },
                }}
            />
        </div>
        <Container maxWidth="md" sx={{ pb: 5, background: "#ffffff4f" }}>
            <SectionHeading>about shreekalp</SectionHeading>
            <SecondaryHeading>
                Your Companion, Mentor & Guide for Life
            </SecondaryHeading>
            <Box
                sx={{
                    display: "flex",
                    flexDirection: { xs: "column", md: "row" },
                    gap: "10px",
                }}
            >
                <div>
                    <ContentText bold>
                        Shreekalp is a contemporary spiritual mentor. He has an
                        art of human energy transformation and mastery over
                        energy balancing by removing the negative energy from
                        our inner system which will immensely increase our
                        potential.{" "}
                    </ContentText>
                    <ContentText bold>
                        Shreekalp is the creator of Divine Harmony, an energy
                        enhancement therapy, through which he guides as a
                        companion and mentor for ultimate self liberation. In
                        divine harmony session your inner system get
                        nourishment.
                    </ContentText>
                    <ContentText bold>
                        Shreekalp is blessed with a great ability of energy
                        reading which helps us to resolve our life issues by his
                        accurate guidance. Being a modern monk Shreekalp
                        understand the demand of current time and lifestyle. In
                        todays competitive environment we all are so busy in
                        achieving our goals and struggling for time management,
                        work life balance etc. and that's why he has invented a
                        unique simplified form of spirituality which is very
                        easy to adopt as it doesn't require any daily meditation
                        practice to follow and yet we can get all the benefits
                        of meditations. His unique style of spirituality make
                        our life simple and stress free.
                    </ContentText>
                </div>
                <MediaImage
                    src={dada_4_img}
                    sx={{
                        width: { xs: 302, md: 454 },
                        height: { xs: 354, md: 508 },
                    }}
                />
            </Box>
            <ContentText bold>
                Shreekalp can transform your energies and rendezvous yourself
                with real you.
            </ContentText>
            <ContentText bold>
                Shreekalp had interest in spirituality from an early childhood
                and gives spiritual guidance and discourses. In his
                companionship one can experience love, knowledge and happiness
                of mankind to do spiritual celebration of life. Along with his
                spiritual interest Shreekalp has love for social activities for
                uplifting the under-privileged people of the society.
            </ContentText>
            <ContentText bold>
                Shreekalp has an ability to understand human emotions and has
                art to visualize energy patterns of people. Based on that he can
                suggest how to maintain energy with in a person, and how to
                develop it for giving a meaning to the individual life. He has
                creativity to tailor expectations, dreams, goals and views; he
                can even customize them as per the interest of the individual.
            </ContentText>
        </Container>
    </Grid>
);
const PublicShreekalpInfo = () => {
    return (
        <div>
            <Box>
                <Header currentPage="shreekalp" />
                <Grid container spacing={{ xs: 1, md: 3 }}>
                    <HeadingSection />
                    <AboutShreekalp />
                    <WhatYouGet />
                    <ContactUs />
                </Grid>
            </Box>
            <BackgroundImg />
        </div>
    );
};

export default PublicShreekalpInfo;
